// import { Box, Button, Dialog, Grid, TextField, Typography } from '@mui/material'
// import React, { useEffect, useState } from 'react'
// import MedicineDoses, { MedicineDose } from '../../../../OPD/add-patient-record/MedicineDoses'
// import CustomAutocomplete from '../../../../autocomplete-field';
// import axios from 'axios';
// import PostTemplate from './PostTemplate';

// interface PostOperatioProps {
//     onChange: (data: any) => void;
//     data: FormData;
//     otherTabsData: any;
//     profile: any;
// }
// interface FormData {
//     medicines: [];
//     guidance: string
//     selectedTemplate: any[]
// }

// const PostOperation: React.FC<PostOperatioProps> = ({ onChange, data, otherTabsData, profile }) => {
//     const [medicineData, setMedicineData] = useState<MedicineDose[]>([]);
//     const [formData, setFormData] = useState<FormData>({
//         medicines: [],
//         guidance: '',
//         selectedTemplate: [],
//     });
//     const [isMultiline, setIsMultiline] = useState(false);
//     const [temp, setTemp] = useState<any[]>([])
//     const [opnTemp, setOpnTemp] = useState<boolean>(false);

//     const handleMedicineDoses = (medicineDoses: any[]) => {
//         const updatedFormData: any = {
//             ...formData,
//             medicines: medicineDoses,
//         };
//         setFormData(updatedFormData);
//         onChange(updatedFormData);
//     };
//     const handleBlur = (event: any) => {
//         setIsMultiline(false);
//     };

//     const handleFocus = () => {
//         setIsMultiline(true);
//     };

//     const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name: string; value: string | string[] }>, index?: number) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//         onChange({ ...formData, [name]: value });
//     };

//     useEffect(() => {
//         if (data) {
//             setFormData((prevData: any) => {
//                 const updatedData = { ...prevData, ...data };
//                 onChange(updatedData);
//                 return updatedData;
//             });

//             if (data?.medicines) {
//                 setMedicineData(data.medicines);
//             } else {
//                 setMedicineData([]);
//             }
//         }
//     }, [data]);

//     const getOpTemplate = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/getAllTemplates?ope_type=post`, {
//                 headers: {
//                     "Content-Type": "application/json",
//                     Authorization: `Bearer ${localStorage.getItem("token")}`,
//                 },
//             })
//             setTemp(response.data?.data || [])
//         } catch (error) {
//             console.log(error);

//         }
//     }

//     useEffect(() => {
//         getOpTemplate()
//     }, [])

//     const handleSelectChangeTemplate = (e: any, value?: string) => {
//         const selectedTemplate = e.target.value;
//         setFormData((prevData) => ({
//             ...prevData,
//             selectedTemplate,
//         }));
//         onChange({ ...formData, selectedTemplate });
//     };

//     function handleInputChangeTemplateBlur(event: any) {
//         throw new Error('Function not implemented.');
//     }

//     const handleAddButtonClickTemplate = () => {
//         setOpnTemp(true)
//     }
//     const handleCloseDialog = () => {
//         setOpnTemp(false); // Close dialog
//     };

//     return (
//         <Box>
//             <Grid container>
//                 <Grid item md={4} sm={4} xs={12}>
//                     <CustomAutocomplete
//                         multiple
//                         options={temp || []}
//                         value={}
//                         onChange={(event, newValue) => {
//                             handleSelectChangeTemplate({
//                                 target: {
//                                     value: newValue.map((diag: any) => diag._id),
//                                 },
//                             });
//                         }}
//                         getOptionLabel={(option) => option?.templateName}
//                         filterOptions={(options, { inputValue }) =>
//                             options.filter((option: any) =>
//                                 option?.templateName
//                                     ?.toLowerCase()
//                                     .includes(inputValue.toLowerCase())
//                             )
//                         }
//                         placeholder="Provisional Diagnosis/DD"
//                         onTextFieldChange={(event) =>
//                             handleSelectChangeTemplate(event, event.target.value)
//                         }
//                         handleAddClick={handleAddButtonClickTemplate}
//                     />
//                 </Grid>
//             </Grid>
//             <Grid container spacing={1}>
//                 <Grid item md={12}>
//                     <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: '600' }}>Guidance</Typography>
//                     <TextField
//                         size="small"
//                         placeholder="Treatment Guidance"
//                         variant="outlined"
//                         fullWidth
//                         name="guidance"
//                         value={formData?.guidance}
//                         InputProps={{
//                             style: {
//                                 borderRadius: "12px",
//                                 background: "#F5F5FB",
//                                 fontSize: "12px",
//                             },
//                         }}
//                         sx={{
//                             "& .MuiOutlinedInput-root": {
//                                 "& fieldset": {
//                                     border: "none",
//                                 },
//                                 "&:hover fieldset": {
//                                     border: "none",
//                                 },
//                                 "&.Mui-focused fieldset": {
//                                     border: "none",
//                                 },
//                             },
//                             "& .MuiInputLabel-root": {
//                                 top: "-2px",
//                             },
//                         }}
//                         onChange={handleChange}
//                         onFocus={handleFocus}
//                         onBlur={handleBlur}
//                         multiline={isMultiline}
//                         minRows={isMultiline ? 3 : 1}
//                     />
//                 </Grid>
//                 <Grid item md={12} sm={12} xs={12}>
//                     <MedicineDoses
//                         onMedicineDoses={handleMedicineDoses}
//                         medicineDataIfExist={medicineData}
//                         setMedicineData={setMedicineData}
//                         medicineData={medicineData}
//                     />
//                 </Grid>
//             </Grid>

//             <Dialog open={opnTemp} onClose={handleCloseDialog} fullWidth maxWidth="md">
//                 <Box p={1}>
//                     <PostTemplate />
//                 </Box>
//             </Dialog>
//         </Box>
//     )
// }

// export default PostOperation




import { Box, Button, Dialog, Grid, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MedicineDoses, { MedicineDose } from '../../../../OPD/add-patient-record/MedicineDoses';
import CustomAutocomplete from '../../../../autocomplete-field';
import axios from 'axios';
import PostTemplate from './PostTemplate';
import POCI from './POCI';

interface PostOperatioProps {
    onChange: (data: any) => void;
    data: any;
    otherTabsData: any;
    profile: any;
}

interface FormData {
    medicines: [];
    guidance: string;
}

const PostOperation: React.FC<PostOperatioProps> = ({ onChange, data, otherTabsData, profile }) => {
    const [medicineData, setMedicineData] = useState<MedicineDose[]>([]);
    const [formData, setFormData] = useState<any>({
        medicines: [],
        guidance: '',
        poci: {
            Nill_Per_Oral: true,
            Keep_the_patient: true,
            Monitor_Vital: true,
            Monitor_Drain: true,
            Monitor_Dressing: true,
            Monitor_Urine: true,
            Special_Order: true,
            Nill_Hours: "", 
            Monitor_Vital_Hourly: "", 
            Monitor_Vital_Hours: "", 
        },
    });
    const [isMultiline, setIsMultiline] = useState(false);
    const [temp, setTemp] = useState<any[]>([]);
    const [opnTemp, setOpnTemp] = useState<boolean>(false);
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null);  // Track selected template
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleTimeOutUpdate = (updatedTimeOut: any) => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            poci: updatedTimeOut,
        }));
    };

    const handleMedicineDoses = (medicineDoses: any[]) => {
        const updatedFormData: any = {
            ...formData,
            medicines: medicineDoses,
        };
        setFormData(updatedFormData);
    };

    const handleBlur = (event: any) => {
        setIsMultiline(false);
    };

    const handleFocus = () => {
        setIsMultiline(true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name: string; value: string | string[] }>, index?: number) => {
        const { name, value } = e.target;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    useEffect(() => {
        onChange(formData);
    }, [formData]);


    useEffect(() => {
        if (data) {
            setFormData(data);
            if (data?.medicines) {
                setMedicineData(data?.medicines);
            } else if (data?.poci) {
                const pociId = setTimeout(() => {
                    if (data?.poci) {
                        handleTimeOutUpdate(data?.poci);
                    }
                }, 500);
                return () => clearTimeout(pociId);
            }
            else {
                setMedicineData([]);
            }
        }
    }, [data]);

    const getOpTemplate = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/getAllTemplates?ope_type=post`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            setTemp(Array.isArray(response.data?.data) ? response.data?.data : []);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getOpTemplate();
    }, []);

    const handleSelectChangeTemplate = (e: any, value?: string) => {
        const selectedTemplate: any = value;
        setSelectedTemplate(selectedTemplate);
        if (selectedTemplate) {
            const guidance = selectedTemplate?.templateData?.guidance || '';
            const medicines = selectedTemplate?.templateData?.medicines || [];
            const updatedFormData = {
                ...formData,
                guidance: guidance,
                medicines: medicines,
            };
            setMedicineData(medicines);
            setFormData(updatedFormData);
        }
    };

    const handleAddButtonClickTemplate = () => {
        setOpnTemp(true);
    };

    const handleOpenClose = () => {
        setIsOpen(!isOpen)
    }

    const handleCloseDialog = () => {
        setOpnTemp(false);
        getOpTemplate()
    };

    return (
        <Box>
            <Grid container>
                <Grid item md={4} sm={4} xs={12}>
                    <CustomAutocomplete
                        options={temp || []}
                        value={selectedTemplate}
                        onChange={(event: any, newValue: any) => {
                            handleSelectChangeTemplate(event, newValue);
                        }}
                        getOptionLabel={(option) => option?.templateName || ''}
                        filterOptions={(options, { inputValue }) =>
                            options?.filter((option: any) =>
                                option?.templateName
                                    ?.toLowerCase()
                                    .includes(inputValue.toLowerCase())
                            )
                        }
                        placeholder="Select Template"
                        handleAddClick={handleAddButtonClickTemplate}
                    />
                </Grid>
                <Grid item md={8} sm={8} xs={4}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip placement='top' title='Post Operative Care Instructions'>
                            <Button size='small' variant='contained' sx={{
                                background: formData?.poci && Object?.values(formData?.poci)?.some(value => value === true) ? '#009E65' : '#FFBF00',
                                "&:hover": {
                                    background: formData?.poci && Object?.values(formData?.poci)?.some(value => value === true) ? '#009E65' : '#FFBF00',
                                },
                            }}
                                onClick={handleOpenClose}
                            >
                                POCI
                            </Button>
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: '600' }}>Guidance</Typography>
                    <TextField
                        size="small"
                        placeholder="Treatment Guidance"
                        variant="outlined"
                        fullWidth
                        name="guidance"
                        value={formData?.guidance}
                        InputProps={{
                            style: {
                                borderRadius: "12px",
                                background: "#F5F5FB",
                                fontSize: "12px",
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                },
                                "&:hover fieldset": {
                                    border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                    border: "none",
                                },
                            },
                            "& .MuiInputLabel-root": {
                                top: "-2px",
                            },
                        }}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        multiline={isMultiline}
                        minRows={isMultiline ? 3 : 1}
                    />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <MedicineDoses
                        onMedicineDoses={handleMedicineDoses}
                        medicineDataIfExist={medicineData}
                        setMedicineData={setMedicineData}
                        medicineData={medicineData}
                        treatment={true}
                    />
                </Grid>
            </Grid>

            <Dialog open={opnTemp} onClose={handleCloseDialog} fullWidth maxWidth="md">
                <Box p={1}>
                    <PostTemplate handleClose={handleCloseDialog} />
                </Box>
            </Dialog>
            <POCI isOpen={isOpen} handleOpenClose={handleOpenClose} onTimeOutUpdate={handleTimeOutUpdate} initialFormState={formData?.poci} />
        </Box>
    );
};


export default PostOperation;