import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Divider,
  Dialog,
  Button,
} from "@mui/material";
import dayjs from "dayjs";
import logo from "../../../../../assets/SVG/OPDIcon/logo.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";

interface Form {
  deathData?: any;
  allData?: any
  formName?: string
  id?: string
  handleClose?: () => void;
  directPrint?: boolean
}

const DeathReport: React.FC<Form> = ({ deathData, allData, formName, id, handleClose, directPrint }) => {
  const projectProfile = useSelector(
    (state: any) => state?.projectProfileSlice?.projectProfile
  );
  const DischargeReportRef = useRef()
  const [deathDataa, setDeathDataa] = useState<any>(deathData)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [logoLoadingError, setLogoLoadingError] = useState(false);
  const profile = useSelector((state: any) => state?.profileSlice?.profile);
  const navigate = useNavigate()
  const handleLogoError = () => {
    setLogoLoadingError(true);
  };

  const defaultData = {
    mrn: deathDataa?.mrn || "",
    patientName: deathDataa?.patientName || "",
    guardiansName: deathDataa?.guardiansName || "",
    gender: deathDataa?.gender || "",
    dateOfAdmission: deathDataa?.dateOfAdmission ? dayjs(deathDataa?.dateOfAdmission).format("DD.MM.YYYY") : "N/A",
    dateOfDeath: deathDataa?.dateOfDeath ? dayjs(deathDataa?.dateOfDeath).format("DD.MM.YYYY") : "N/A",
    timeOfDeath: deathDataa?.timeOfDeath ? dayjs(deathDataa?.timeOfDeath).format("hh:mm A") : "N/A",
    diagnosis:
      deathDataa?.diagnosis?.length > 0
        ? deathDataa?.diagnosis?.join(", ")
        : "No diagnosis available",
    primaryCauseOfDeath: deathDataa?.primaryCauseOfDeath || "",
    secondaryCauseOfDeath: deathDataa?.secondaryCauseOfDeath || "",
    doctorName: deathDataa?.doctorName || "",
    currentDate: deathDataa?.currentDate ? dayjs(deathData?.currentDate).format("DD.MM.YYYY") : "N/A",
    dateOfDoc: deathDataa?.dateOfDoc ? dayjs(deathData?.dateOfDoc).format("DD.MM.YYYY") : "N/A",
    // timeOfDoc: deathData?.timeOfDoc || "N/A",
    timeOfDoc: deathDataa?.timeOfDoc ? dayjs(deathData?.timeOfDoc).format("hh:mm A") : "N/A",
    deadBodyReceivedBy: deathDataa?.deadBodyReceivedBy || "",
    relationWithDiseased: deathDataa?.relationWithDiseased || "",
    cnicOfReceiver: deathDataa?.cnicOfReceiver || "",
    phoneNumberOfReceiver: deathDataa?.phoneNumberOfReceiver || "",

  };

  const fetchFormData = async (formName: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${id}?formName=${formName}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const fetchedData = await response?.data?.data?.formData
      setDeathDataa(fetchedData)
      console.log("fetchedData", fetchedData)
    }
    catch (error) {
      toast.error('Failed to fetch form data.');
    }
  };

  useEffect(() => {
    if (formName) {
      fetchFormData(formName)
    }
  }, [id, formName])

  const handlePrint = useReactToPrint({
    content: () => DischargeReportRef?.current || null,
    documentTitle: "Patient Refer Report",
    onAfterPrint: () => navigate('/ipd-dashboard'),
    pageStyle: "A4",
  });

  const handlePrintData = () => {
    setTimeout(() => handlePrint(), 1000);
  };

  useEffect(() => {
    if (directPrint) {
      setDialogOpen(true);
    }
  }, [directPrint]);

  const handleYesPrint = () => {
    handlePrintData();
    setDialogOpen(false);
  };

  const handleCancelPrint = () => {
    if (handleClose) {
      handleClose();
    }
  };

  return (
    // sx={{ border: "1px solid #ddd" }}
    <Box sx={{padding:'20px'}}  ref={DischargeReportRef}>
      <Box
        sx={{
          width: "100%",
          backgroundSize: "cover",
          height: "100px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box display="flex" alignItems="center">
          {!logoLoadingError ? (
            <img
              src={projectProfile?.hospitalLogoUrl}
              alt="Hospital Logo"
              style={{ width: "70px", height: '70px' }}
              onError={handleLogoError}
            />
          ) : (
            <img src={logo} alt="Default Logo" />
          )}
          <Typography
            variant="h4"
            sx={{
              fontWeight: "600",
              color: "#000",
              fontSize: "16px",
              textTransform: "capitalize",
              ml: 2
            }}
          >
            {projectProfile?.hospitalName}
          </Typography>
        </Box>
      </Box>
      <Box mb={2}>
        {allData?.patientData && (
          <Box p={2} sx={{ borderBottom: '2px solid #000' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6" style={{ color: '#000', fontWeight: 'bold', fontSize: '12px' }}>Patient Name : <span style={{ color: '#000', fontSize: '12px', fontWeight: 'normal', textTransform: 'capitalize' }}>{allData?.patientData?.patientName}</span></Typography>
              </Grid>
              <Grid item xs={6}>
                <Box display='flex' justifyContent='flex-end'>
                  <Typography variant="h6" style={{ color: '#000', fontWeight: 'bold', fontSize: '12px' }}>MRN # : <span style={{ color: '#000', fontSize: '12px', fontWeight: 'normal' }}>{allData?.patientData?.mrn}</span></Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Typography variant="h6" align="center" gutterBottom sx={{ fontSize: "13px" }}>
        DEATH CERTIFICATE{" "}
        <Typography
          component="span"
          sx={{ fontSize: "10px", fontWeight: "normal" }}
        >
          (This is an e-form so no signature is needed)
        </Typography>
      </Typography>


      <Divider sx={{ mb: 2 }} />

      <Grid container spacing={1}>
        {/* Patient Details */}
        <Grid item xs={12}>
          <TableContainer>
            <Table size="small" sx={{ border: "1px solid #ddd" }}>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>
                    <strong>MR Number</strong>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>{defaultData?.mrn}</TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>
                    <strong>Date</strong>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>{defaultData?.currentDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>
                    <strong>Patient Name</strong>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>{defaultData?.patientName}</TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>
                    <strong>Date of Admission</strong>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>{defaultData?.dateOfAdmission}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>
                    <strong>S/o, D/o, W/o</strong>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>{defaultData?.guardiansName}</TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>
                    <strong>Age/Sex</strong>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>{defaultData?.gender}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>
                    <strong>Date of Death</strong>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>{defaultData?.dateOfDeath}</TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>
                    <strong>Time of Death</strong>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>{defaultData?.timeOfDeath}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Divider sx={{ width: "100%", my: 2 }} />

        {/* Diagnosis */}
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold" sx={{ fontSize: "10px" }}>
            Diagnosis:
          </Typography>
          <Typography sx={{ fontSize: "10px" }}>{defaultData?.diagnosis}</Typography>
        </Grid>

        <Divider sx={{ width: "100%", my: 1 }} />

        {/* Cause of Death */}
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold" sx={{ fontSize: "10px" }}>
            Primary Cause of Death:
          </Typography>
          <Typography sx={{ fontSize: "10px" }}>{defaultData?.primaryCauseOfDeath}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold" sx={{ fontSize: "10px" }}>
            Secondary Cause of Death:
          </Typography>
          <Typography sx={{ fontSize: "10px" }}>{defaultData?.secondaryCauseOfDeath}</Typography>
        </Grid>

        <Divider sx={{ width: "100%", my: 1 }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" sx={{ fontSize: "10px" }}>
              Dead Body Receiver:
            </Typography>
            <Typography sx={{ fontSize: "10px" }}>{defaultData?.deadBodyReceivedBy}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" sx={{ fontSize: "10px" }}>
              Receiver with Relation:
            </Typography>
            <Typography sx={{ fontSize: "10px" }}>{defaultData?.relationWithDiseased}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" sx={{ fontSize: "10px" }}>
              Receiver CNIC:
            </Typography>
            <Typography sx={{ fontSize: "10px" }}>{defaultData?.cnicOfReceiver}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" sx={{ fontSize: "10px" }}>
              Receiver Phone#:
            </Typography>
            <Typography sx={{ fontSize: "10px" }}>{defaultData?.phoneNumberOfReceiver}</Typography>
          </Grid>
        </Grid>


        <Divider sx={{ width: "100%", my: 1 }} />

        {/* Doctor Details */}
        <Grid item xs={12}>
          <TableContainer>
            <Table size="small" sx={{ border: "1px solid #ddd" }}>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>
                    <strong>Doctor Name:</strong> {defaultData?.doctorName}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>
                    <strong>Date:</strong> {defaultData?.dateOfDoc}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd", fontSize: "10px" }}>
                    <strong>Time:</strong> {defaultData?.timeOfDoc}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Dialog open={dialogOpen} maxWidth='xs' fullWidth>
        <Box p={3}>
          <Typography sx={{fontSize:'12px', fontWeight:'500', textAlign:'center'}}>Do you want to print the report?</Typography>
          <Box mt={2} display="flex" justifyContent="center" gap={1}>
            <Button size='small' variant="outlined" onClick={handleCancelPrint}>
              No
            </Button>
            <Button size='small' variant="contained" onClick={handleYesPrint}>
              Yes
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default DeathReport;
