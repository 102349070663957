import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    Grid,
    Chip,
    Tooltip,
} from '@mui/material';
import { Bounce, toast } from "react-toastify";
import axios from 'axios';

interface OTProps {
    onChange: (data: any) => void;
    data: any;
    otherTabsData: any;
    profile: any;
    opdData: any
}

const OT: React.FC<OTProps> = ({ onChange, data, otherTabsData, profile, opdData }) => {
    const [formData, setFormData] = useState<any>({
        mrn: '',
        patientName: '',
        guardiansName: '',
        diagnosis: [],
        procedure: opdData?.procedure,
        surgeon: '',
        anaesthetist: '',
        planned_Anaesthesia: '',
        expected_Duration: '',
        actual_Time_Of_Operation: '',
        ot_Number: '',
        status: '',
        reason: '',
        anaesthesia_Given: '',
        // prophylacticAntibiotic: '',
        // adverseEvent: '',
    });
    const [signInTime, setSignInTime] = useState<any>()

    const fetchFormData = async (formName: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${otherTabsData?._id}?formName=${formName}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
             if (formName === "Pre_Surgical") {
                setFormData((prevData: any) => ({
                    ...prevData,
                    surgeon: response?.data?.data?.formData?.Surgeon?.fullName,
                    anaesthetist: response?.data?.data?.formData?.anaesthetist?.fullName,
                    diagnosis: response?.data?.data?.formData?.diagnosis,
                }));
            } else if (formName == "ot_room_anaesthesia_monitoring") {
                setSignInTime(response?.data?.data?.formData?.sign_in?.savedAt)
            }
            else {
                setFormData((prevData: any) => ({
                    ...prevData,
                    planned_Anaesthesia: response?.data?.data?.formData?.anaesthesia_plan,
                }));
            }
        } catch (error) {
            toast.error('Failed to fetch form data.');
        }
    };    

    useEffect(() => {
        fetchFormData('Pre_Surgical');
        fetchFormData('Pre_Anesthesia_Assessment');
        fetchFormData('ot_room_anaesthesia_monitoring');
    }, []);

    useEffect(() => {
        if (otherTabsData) {
            setFormData((prevData: any) => ({
                ...prevData,
                mrn: otherTabsData?.mrn,
                patientName: otherTabsData?.patientName,
                guardiansName: otherTabsData?.guardiansName,
            }));
        }
    }, [otherTabsData]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => {
            const updatedData = {
                ...prevData,
                [name]: value,
            };
            onChange(updatedData);
            return updatedData;
        });
    };

    useEffect(() => {
        if (data) {
            setFormData((prevData: any) => ({
                ...prevData,
                adverseEvent: data?.adverseEvent,
                anaesthesia_Given: data?.anaesthesia_Given,
                actual_Time_Of_Operation: data?.actual_Time_Of_Operation,
                expected_Duration: data?.expected_Duration,
                ot_Number: data?.ot_Number,
                status: data?.status,
                reason: data?.reason,
                prophylacticAntibiotic: data?.prophylacticAntibiotic,
            }))
        }
    }, [data])

    useEffect(() => {
        if (signInTime) {
            const currentTime = new Date();
            const signInDate = new Date(signInTime);
            const timeDifference = Math.abs(currentTime.getTime() - signInDate.getTime());
            const minutesDifference = Math.floor(timeDifference / (1000 * 60));
            const days = Math.floor(minutesDifference / (60 * 24));
            const hours = Math.floor((minutesDifference % (60 * 24)) / 60);
            const minutes = minutesDifference % 60;
            const formattedTime = `${days}days ${hours}h ${minutes}m`;
            setFormData((prevData: any) => ({
                ...prevData,
                actual_Time_Of_Operation: formattedTime,
            }));
        }
    }, [signInTime]);

    return (
        <Box >
            <Typography variant="h6">Operation Theatre Information</Typography>

            <Grid container spacing={1.4} sx={{ mt: 0.5 }}>
                <Grid item md={2.4} sm={4} xs={6} sx={{ marginTop: '0px', marginBottom: '0px' }}>
                    {/* <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>MRN :</Typography> */}
                    <TextField
                        fullWidth
                        label="MRN"
                        size='small'
                        className='search-field'
                        value={formData?.mrn}
                        disabled
                    />
                </Grid>
                <Grid item md={2.4} sm={4} xs={6}>
                    {/* <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Patient Name :</Typography> */}
                    <TextField
                        fullWidth
                        label="Patient Name"
                        className='search-field'
                        size='small'
                        value={formData?.patientName}
                        disabled
                    />
                </Grid>
                <Grid item md={2.4} sm={4} xs={6}>
                    {/* <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Guardian Name :</Typography> */}
                    <TextField
                        fullWidth
                        label="Guardian's Name"
                        className='search-field'
                        value={formData?.guardiansName}
                        size='small'
                        disabled
                    />
                </Grid>
                <Grid item md={2.4} sm={4} xs={6}>
                    {/* <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Diagnosis :</Typography> */}

                    {/* <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            background: formData?.diagnosis?.length > 0 ? "rgba(192, 192, 192, 0.2)" : "",
                            border: formData?.diagnosis?.length > 0 ? "2px solid #D8D8D8" : "",
                            p: 0.5,
                            borderRadius: "12px",
                        }}
                    >
                        {formData?.diagnosis?.map((sign: any, index: any) => (
                            <Chip
                                size="small"
                                key={index}
                                label={sign}
                                sx={{
                                    borderRadius: "8px",
                                    background: "#fff",
                                    border: "1px solid rgba(0, 0, 0, 0.22)",
                                    fontSize: "12px",
                                    "&:hover": {
                                        background: "#055FFC",
                                        color: "#fff",
                                        "& .MuiChip-deleteIcon": {
                                            color: "#fff",
                                        },
                                        "& .MuiChip-icon": {
                                            color: "#fff",
                                        },
                                    },
                                }}
                            />
                        ))}
                    </Box> */}
                    <Typography sx={{ fontSize: '10px', fontWeight: '600' }}>Diagnosis :</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Typography sx={{ fontSize: '10px', color: '#000', fontWeight: '500' }}>
                            {
                                formData?.diagnosis?.slice(0, 2).map((test: any) => test).join(', ') || 'No Diagnosis'
                            }
                            {formData?.diagnosis?.length > 2 && (
                                <Tooltip placement='top'
                                    title={formData?.diagnosis?.slice(2).map((test: any) => test)?.join(', ')}
                                    arrow
                                >
                                    <span style={{ cursor: 'pointer', color: '#1976d2' }}> +{formData?.diagnosis?.length - 2} more</span>
                                </Tooltip>
                            )}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={2.4} sm={4} xs={6}>
                    <Typography sx={{ fontSize: '10px', fontWeight: '600' }}>Procedure :</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Typography sx={{ fontSize: '10px', color: '#000', fontWeight: '500' }}>
                            {formData?.procedure}
                            {/* {
                                formData?.procedure?.slice(0, 4).map((test: any) => test?.investigationName).join(', ') || 'No Previous Tests'
                            }
                            {formData?.procedure?.length > 4 && (
                                <Tooltip placement='top'
                                    title={formData?.procedure?.slice(4).map((test: any) => test?.investigationName)?.join(', ')}
                                    arrow
                                >
                                    <span style={{ cursor: 'pointer', color: '#1976d2' }}> +{formData?.procedure?.length - 4} more</span>
                                </Tooltip>
                            )} */}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={2.4} sm={4} xs={6}>
                    {/* <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Surgeon :</Typography> */}
                    <TextField
                        fullWidth
                        label="Surgeon"
                        className='search-field'
                        size='small'
                        value={formData?.surgeon}
                        disabled
                    />
                </Grid>
                <Grid item md={2.4} sm={4} xs={6}>
                    {/* <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Anaesthetist :</Typography> */}
                    <TextField
                        fullWidth
                        label="Anaesthetist"
                        className='search-field'
                        value={formData?.anaesthetist}
                        size='small'
                        disabled
                    />
                </Grid>
                <Grid item md={2.4} sm={4} xs={6}>
                    {/* <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Planned Anaesthesia :</Typography> */}
                    <TextField
                        fullWidth
                        label="Planned Anaesthesia"
                        className='search-field'
                        value={formData?.planned_Anaesthesia}
                        size='small'
                        disabled
                    />
                </Grid>
                <Grid item md={2.4} sm={4} xs={6}>
                    {/* <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Expected Duration :</Typography> */}
                    <TextField
                        fullWidth
                        label="Expected Duration"
                        name="expected_Duration"
                        className='search-field'
                        value={formData?.expected_Duration}
                        size='small'
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item md={2.4} sm={4} xs={6}>
                    {/* <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Actual Time of Operation :</Typography> */}
                    <TextField
                        fullWidth
                        label="Actual Time of Operation"
                        className='search-field'
                        name="actual_Time_Of_Operation"
                        value={formData?.actual_Time_Of_Operation}
                        size='small'
                        disabled
                    />
                </Grid>
                <Grid item md={2.4} sm={4} xs={6}>
                    {/* <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>OT Number :</Typography> */}
                    <TextField
                        fullWidth
                        label="OT Number"
                        name="ot_Number"
                        size='small'
                        className='search-field'
                        value={formData?.ot_Number}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item md={2.4} sm={4} xs={6}>
                    {/* <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Status :</Typography> */}
                    <FormControl fullWidth size='small' >
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                            labelId="status-label"
                            name="status"
                            value={formData?.status}
                            onChange={handleChange}
                            sx={{
                                borderRadius: "12px",
                                background: "#F5F5FB",
                                fontSize: "12px",
                                height: "38px",
                                "& fieldset": { border: "none" },
                            }}
                        >
                            <MenuItem value="done">Done</MenuItem>
                            <MenuItem value="pending">Pending</MenuItem>
                            <MenuItem value="postponed">Postponed</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {formData?.status === "postponed" && (
                    <Grid item md={2.4} sm={4} xs={6}>
                        {/* <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Reason :</Typography> */}
                        <TextField
                            fullWidth
                            label="Postpond Reason"
                            name="reason"
                            size='small'
                            className='search-field'
                            value={formData?.reason}
                            onChange={handleChange}
                        />
                    </Grid>
                )}
                <Grid item md={2.4} sm={4} xs={6}>
                    <Typography sx={{ fontSize: '10px', fontWeight: '600' }}>Anaesthesia Given:</Typography>
                    <RadioGroup
                        sx={{ p: 0 }}
                        row
                        name="anaesthesia_Given"
                        value={formData?.anaesthesia_Given}
                        onChange={handleChange}
                    >
                        <FormControlLabel sx={{ p: 0.2 }} value="yes" control={<Radio sx={{
                            "& .MuiSvgIcon-root": {
                                height: 15,
                                width: 15,
                            }
                        }} />} label={<span style={{ fontSize: '12px' }}>Yes</span>} />
                        <FormControlLabel value="no" control={<Radio sx={{
                            "& .MuiSvgIcon-root": {
                                height: 15,
                                width: 15,
                            }
                        }} />} label={<span style={{ fontSize: '12px' }}>No</span>} />
                    </RadioGroup>
                </Grid>

                {/* <Grid item md={2.4} sm={4} xs={6}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Prophylactic Antibiotic:</Typography>
                    <RadioGroup
                        row
                        name="prophylacticAntibiotic"
                        value={formData?.prophylacticAntibiotic}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="yes" control={<Radio size='small' />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio size='small' />} label="No" />
                    </RadioGroup>
                </Grid>

                <Grid item md={2.4} sm={4} xs={6}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Anaesthesia Related Adverse Event:</Typography>
                    <RadioGroup
                        row
                        name="adverseEvent"
                        value={formData?.adverseEvent}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="yes" control={<Radio size='small' />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio size='small' />} label="No" />
                    </RadioGroup>
                </Grid> */}

            </Grid>
        </Box>
    );
};

export default OT;
