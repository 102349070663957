import { Box, Grid, TextField, Radio, RadioGroup, FormControlLabel, FormControl, Select, MenuItem, Checkbox, ListItemText, Typography, Autocomplete, Button, IconButton, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MedicineDoses, { MedicineDose } from '../../../../OPD/add-patient-record/MedicineDoses';
import axios from 'axios';
import { Delete as DeleteIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import DynamicTable from '../../../../Dynamic-table';
import { isDateTimeGreaterThan30MinutesAgo } from '../../../../../utils/dateTimeUtils';
import { v4 as uuidv4 } from 'uuid';  // Import uuid package

interface TreatmentProps {
    onChange: (data: any) => void;
    data: any;
    otherTabsData: any;
    profile: any;
    opdData: any
}

interface FormData {
    guidance: string;
    medicines: MedicineDose[];
    transfusion: any;
}

const components = [
    { value: 'PRBC', label: 'PRBC' },
    { value: 'FFP', label: 'FFP' },
    { value: 'Platelets', label: 'Platelets' },
    { value: 'Whole Blood', label: 'Whole Blood' },
];
interface DeletedMedicine {
    medicine: MedicineDose;
    deletedTime: string;
    deletedBy: string;
}
const Treatment: React.FC<TreatmentProps> = ({ onChange, data, otherTabsData, profile, opdData }) => {
    const currentDate = dayjs();
    const [isMultiline, setIsMultiline] = useState(false);
    const [medicineData, setMedicineData] = useState<MedicineDose[]>([]);
    const [formData, setFormData] = useState<FormData>({
        guidance: '',
        medicines: [],
        transfusion: {
            bloodTransfusion: 'Arrange',
            components: [],
            frequency: '',
            pint: '',
            quantity: '',
            duration: '',
            schedule: 'Days'
        },
    });
    const [freq, setFreq] = useState<any[]>([]);
    const [savedTransfusions, setSavedTransfusions] = useState<any[]>([]);
    const [deletedMedicines, setDeletedMedicines] = useState<DeletedMedicine[]>([]);
    const [isBloodTransfusionChecked, setIsBloodTransfusionChecked] = useState(false); // Step 1: Manage checkbox state
    const handleCheckboxChange = (event: any) => {
        setIsBloodTransfusionChecked(event.target.checked);
    };
    const handleBlur = () => setIsMultiline(false);
    const handleFocus = () => setIsMultiline(true);

    const handleDeleteTransfusion = (indexToDelete: number) => {
        setSavedTransfusions((prev: any[]) => {
            if (Array.isArray(prev)) {
                return prev?.filter((_, index) => index !== indexToDelete);
            }
            return [];
        });
    };

    const handleMedicineDoses = (medicineDoses: MedicineDose[], deletedInfo?: { medicine: MedicineDose; deletedTime: string; deletedBy: string }) => {
        if (deletedInfo) {
            setDeletedMedicines((prev: any) => {
                return Array.isArray(prev) ? [...prev, deletedInfo] : [deletedInfo];
            });
        }
        const updatedFormData: any = {
            ...formData,
            medicines: medicineDoses,
        };
        setFormData(updatedFormData);
    };
    useEffect(() =>{
        onChange({medicines : formData.medicines, guidance : formData?.guidance, transfusion: savedTransfusions });
    },[formData, savedTransfusions])

    useEffect(() => {
        if (data) {   
            setFormData({
                ...formData,
                guidance: data?.guidance || '',
                medicines: data?.medicines || [],
            });
            if (data?.medicines) {
                setMedicineData(data?.medicines);
            } else {
                setMedicineData([]);
            }
            if (data?.transfusion) {
                setSavedTransfusions(Array.isArray(data?.transfusion) ? data?.transfusion : []);
            }
            if (data?.deletedMedicines) {
                setDeletedMedicines(data?.deletedMedicines)
            }
        }
        const timeoutId = setTimeout(() => {
            if (!data) {
                if (opdData && data == null) {
                    setMedicineData(opdData?.medicines || []);
                    const updatedFormData: any = {
                        guidance: opdData?.guidance || '',
                        medicines: opdData?.medicines || [],
                    };
                    setFormData(updatedFormData);
                    onChange(updatedFormData);
                }
            }
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [data, opdData]);
       
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any, fieldName?: string) => {
        if (e?.target) {
            const { name, value } = e.target;
            if (name === "guidance") {
                setFormData(prevState => {
                    const updatedFormData = {
                        ...prevState,
                        guidance: value,
                    };
                    return updatedFormData;
                });
            } else if (fieldName) {
                setFormData(prevState => {
                    const updatedFormData = {
                        ...prevState,
                        transfusion: {
                            ...prevState.transfusion,
                            [fieldName]: value,
                        },
                    };
                    return updatedFormData;
                });
            } else {
                setFormData(prevState => {
                    const updatedFormData = {
                        ...prevState,
                        [name]: value,
                    };
                    return updatedFormData;
                });
            }
        } else if (e?.value) {
            const value = e.value;
            setFormData(prevState => {
                const updatedFormData = {
                    ...prevState,
                    transfusion: {
                        ...prevState.transfusion,
                        [fieldName || 'frequency']: value,
                    },
                };
                return updatedFormData;
            });
        }
    };

    const handleComponentChange = (event: any) => {
        const { value } = event.target;
        const updatedComponents = Array.isArray(value) ? value : value?.split(',');
        setFormData((prevState) => {
            const updatedFormData = {
                ...prevState,
                transfusion: {
                    ...prevState.transfusion,
                    components: updatedComponents,
                },
            };
            return updatedFormData;
        });
    };

    const isComponentDisabled = (component: string) => {
        if (formData?.transfusion?.components?.includes('Whole Blood')) {
            return component !== 'Whole Blood';
        } else {
            return component === 'Whole Blood' && formData?.transfusion?.components?.length > 0;
        }
    };

    const handleScheduleChange = (event: any) => {
        const { value } = event.target;
        setFormData({
            ...formData,
            transfusion: {
                ...formData?.transfusion,
                schedule: value,
            },
        });
    };

    const removeTransfusionField = () => {
        setFormData((prevData: any) => ({
            ...prevData,
            transfusion: {
                bloodTransfusion: 'Arrange',
                components: [],
                frequency: '',
                pint: '',
                quantity: '',
                schedule: 'Days',
                duration: ''
            },
        }))
    };

    const handleSaveTransfusions = () => {
        const newTransfusion = {
            ...formData.transfusion,
            id: uuidv4(),
            dateTime: currentDate.format('DD.MM.YYYY hh:mm A'),
            doctorName: profile?.data?.fullName,
        };
        setSavedTransfusions((prev: any) => [...prev, newTransfusion]);
        removeTransfusionField()
        setIsBloodTransfusionChecked(false)
    };

    const fetchSchedules = () => {
        axios.get(`${process.env.REACT_APP_OPD_API_URL}/dosage-schedules/getAllByPagination?pageNo=1&count=50000`, {
            headers: {
                accept: "*/*",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then(response => {
                setFreq(response?.data?.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchSchedules();
    }, []);

    return (
        <Box mt={0.5}>
            <Grid container>
                <Grid item md={12} sm={12} xs={12} spacing={1}>
                    <TextField
                        size="small"
                        placeholder="Treatment Guidance"
                        variant="outlined"
                        fullWidth
                        label='Treatment Guidance'
                        name="guidance"
                        value={formData?.guidance}
                        InputProps={{
                            style: {
                                borderRadius: "12px",
                                background: "#F5F5FB",
                                fontSize: "12px",
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                },
                                "&:hover fieldset": {
                                    border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                    border: "none",
                                },
                            },
                            "& .MuiInputLabel-root": {
                                top: "-2px",
                            },
                        }}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        multiline={isMultiline}
                        minRows={isMultiline ? 3 : 1}
                    />
                </Grid>

                <Grid item md={12} sm={12} xs={12} sx={{ mt: 1, mb: 1 }}>
                    <MedicineDoses
                        onMedicineDoses={handleMedicineDoses}
                        medicineDataIfExist={medicineData}
                        setMedicineData={setMedicineData}
                        medicineData={medicineData}
                        treatment={true}
                    />
                </Grid>
            </Grid>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Blood Transfusion</Typography>
                <Checkbox
                    checked={isBloodTransfusionChecked}
                    onChange={handleCheckboxChange}
                    size='small'
                />
            </Box>
            {isBloodTransfusionChecked && (<Grid container spacing={1} >
                <Grid item md={3}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Transfusion</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            name="bloodTransfusion"
                            value={formData?.transfusion?.bloodTransfusion}
                            onChange={(e) => handleChange(e, 'bloodTransfusion')}
                            sx={{
                                fontSize: '12px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <FormControlLabel
                                value="Arrange"
                                control={<Radio size='small' sx={{ fontSize: '12px' }} />}
                                label="Arrange"
                                sx={{
                                    fontSize: '12px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            />
                            <FormControlLabel
                                value="Transfuse"
                                control={<Radio size='small' sx={{ fontSize: '12px' }} />}
                                label="Transfuse"
                                sx={{
                                    fontSize: '12px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                {formData?.transfusion?.bloodTransfusion === "Arrange" && <Grid item md={1.5} sm={3} xs={4}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Pint</Typography>
                    <TextField
                        placeholder="Pint"
                        variant="outlined"
                        size="small"
                        value={formData?.transfusion?.pint || ''}
                        onChange={(e) => handleChange(e, 'pint')}
                        className='search-field'
                        type='number'
                        InputProps={{
                            endAdornment: <InputAdornment position="end">pint</InputAdornment>,
                        }}
                    />
                </Grid>}
                {formData?.transfusion?.bloodTransfusion === "Transfuse" && <Grid item md={1.5} sm={3} xs={4}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Quantity</Typography>
                    <TextField
                        placeholder="Quantity"
                        variant="outlined"
                        size="small"
                        value={formData?.transfusion?.quantity || ''}
                        onChange={(e) => handleChange(e, 'quantity')}
                        className='search-field'
                        type='number'
                        InputProps={{
                            endAdornment: <InputAdornment position="end">ml</InputAdornment>,
                        }}
                    />
                </Grid>}
                <Grid item md={2} sm={3} xs={4}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Components</Typography>
                    <Select
                        multiple
                        name="components"
                        value={formData?.transfusion?.components || []}
                        onChange={handleComponentChange}
                        fullWidth
                        size="small"
                        renderValue={(selected) => selected?.join(', ')}
                        sx={{
                            height: '38px',
                            borderRadius: "12px",
                            background: "#F5F5FB",
                            fontSize: "12px",
                            boxShadow: "none",
                            "& fieldset": { border: "none" },
                        }}
                    >
                        {components?.map((component) => (
                            <MenuItem key={component?.value} value={component?.value} disabled={isComponentDisabled(component?.value)}>
                                <Checkbox checked={formData?.transfusion?.components?.includes(component?.value)} />
                                <ListItemText primary={component?.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>

                {formData?.transfusion?.bloodTransfusion === "Transfuse" && <Grid item md={1.5} sm={3} xs={4}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Frequency</Typography>
                    <Autocomplete
                        options={freq}
                        getOptionLabel={(option) => option?.abr_notation || ''}
                        value={formData.transfusion?.frequency}
                        onChange={(event, newValue) => handleChange({ value: newValue }, 'frequency')}
                        renderInput={(params) => <TextField {...params} placeholder="Frequency" size="small" className='search-field' />}
                    />
                </Grid>}

                {formData?.transfusion?.bloodTransfusion === "Transfuse" && <Grid item md={1.5} sm={3} xs={4}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Duration</Typography>
                    <TextField
                        placeholder="Duration"
                        variant="outlined"
                        size="small"
                        value={formData?.transfusion?.duration || ''}
                        onChange={(e) => handleChange(e, 'duration')}
                        className='search-field'
                        type='number'
                    />
                </Grid>}
                {
                    formData?.transfusion?.bloodTransfusion === "Transfuse" && <Grid item md={1.5} sm={3} xs={4}>
                        <Select
                            labelId="duration-unit-label"
                            value={formData?.transfusion?.schedule || "Days"}
                            onChange={handleScheduleChange}
                            placeholder="Units"
                            name="durationUnit"
                            required
                            size="small"
                            sx={{
                                mt: 2,
                                width: "100%",
                                height: '38px',
                                borderRadius: "12px",
                                background: "#F5F5FB",
                                fontSize: "12px",
                                boxShadow: "none",
                                "& fieldset": {
                                    border: "none",
                                },
                            }}
                        >
                            <MenuItem value="Minutes">Minutes</MenuItem>
                            <MenuItem value="Hours">Hours</MenuItem>
                            <MenuItem value="Days">Days</MenuItem>
                            <MenuItem value="Weeks">Weeks</MenuItem>
                            <MenuItem value="Months">Months</MenuItem>
                            <MenuItem value="Years">Years</MenuItem>
                            <MenuItem value="Lifetime">Lifetime</MenuItem>
                        </Select>
                    </Grid>
                }
                <Grid item md={1} sm={2} xs={4} sx={{ mt: 2 }}>
                    <Button size='small' variant='contained' 
                    
                    disabled={
                        
                        !(formData?.transfusion?.components && formData?.transfusion?.pint) &&
                        
                        !(formData?.transfusion?.quantity && formData?.transfusion?.components)
                    }
                    onClick={handleSaveTransfusions}
                    >Save</Button>
                </Grid>
            </Grid>)}
            <Box mt={1}>
                <DynamicTable
                    headers={[
                        "SN", "Blood Transfusion", "Pint", "Quantity", "Components", "Frequency",
                        "Duration", "Doctor Name", "Date & Time", "Action"
                    ]}
                    data={savedTransfusions?.map((transfusion: any, index: any) => [
                        index + 1,
                        transfusion?.bloodTransfusion,
                        transfusion?.pint,
                        `${transfusion?.quantity || ''} ${transfusion?.quantity ? "ml" : "-"}`,
                        transfusion?.components?.join(', '),
                        transfusion?.frequency?.abr_notation || '-',
                        transfusion?.bloodTransfusion === "Arrange"
                            ? '-'
                            : `${transfusion?.duration || ''} - ${transfusion?.schedule || ''}`,
                        transfusion?.doctorName,
                        transfusion?.dateTime,
                    ])}
                    actionButtons={[
                        {
                            icon: (rowIndex: number) => (
                                <DeleteIcon
                                    sx={{
                                        color: isDateTimeGreaterThan30MinutesAgo(savedTransfusions[rowIndex]?.dateTime) ? "#FF5454" : "#D3D3D3",
                                        "&:hover": {
                                            background: "none",
                                            color: isDateTimeGreaterThan30MinutesAgo(savedTransfusions[rowIndex]?.dateTime) ? "rgb(191, 44, 44)" : "#D3D3D3",
                                        },
                                        height: '20px'
                                    }}
                                />
                            ),
                            onClick: (rowIndex: number) => handleDeleteTransfusion(rowIndex),
                            // disabled: (rowIndex: number) => !isDateTimeGreaterThan30MinutesAgo(savedTransfusions[rowIndex]?.dateTime),
                        },
                    ]}
                />

            </Box>
        </Box>
    );
};

export default Treatment;

