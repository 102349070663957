import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  IconButton,
  CircularProgress,
} from "@mui/material";

interface Props {
  headers: string[];
  data: any[];
  loading?: boolean;
  page?: number;
  rowsPerPage?: number;
  handleChangePage?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  totalRows?: number;
  actionButtons?: {
    icon?: React.ReactNode | ((rowIndex: number) => React.ReactNode);
    onClick?: (rowIndex: number) => void;
    disabled?: (rowIndex: number) => boolean;
  }[];
  rowStyleCondition?: (rowIndex: number) => { sx: React.CSSProperties; className: string };
}

const DynamicTable: React.FC<Props> = ({
  headers,
  data,
  loading = false,
  page = 0,
  rowsPerPage = 10,
  handleChangePage,
  handleChangeRowsPerPage,
  totalRows = 0,
  actionButtons,
  rowStyleCondition
}) => {
  return (
    <div style={{ overflowX: "auto", width: "100%" }}>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: { md: "75vh", sm: '100vh', xs: '100vh ' },
          width: "100%",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#077DD3",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "rgba(7, 125, 211, 0.24)",
          },
        }}
      >
        <Table>
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow sx={{ backgroundColor: "#077DD3", color: "#fff" }}>
              {headers?.map((header, index) => (
                <TableCell
                  sx={{
                    fontSize: {
                      md: "0.8em",
                      xs: "0.6em",
                      xl: "0.8em",
                      sm: "0.6em",
                      lg: "0.8em",
                    },
                    p: 0.5,
                    color: "white",
                  }}
                  key={index}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableRow>
              <TableCell
                colSpan={headers.length + (actionButtons ? 1 : 0)}
                sx={{ textAlign: "center" }}
              >
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              {data?.map((row, rowIndex) => {
                const { sx, className } = rowStyleCondition ? rowStyleCondition(rowIndex) : { sx: {}, className: "" };
                return <TableRow
                  key={rowIndex}
                  className={className}
                  sx={{
                    "& > .MuiTableCell-root": { height: "20px" },
                    ...sx, // Apply dynamic row styles
                  }}
                >
                  {Object.values(row ?? {})?.map((cell: any, cellIndex) => (
                    <TableCell
                      sx={{
                        fontSize: {
                          md: "0.8em",
                          xs: "0.6em",
                          xl: "0.8em",
                          sm: "0.6em",
                          lg: "0.8em",
                        },
                        px: 1,
                        py: 0,
                        textTransform: "capitalize",
                      }}
                      key={cellIndex}
                    >
                      {cell}
                    </TableCell>
                  ))}
                  {actionButtons && actionButtons?.length > 0 && (
                    <TableCell
                      sx={{
                        fontSize: {
                          md: "0.8em",
                          xs: "0.6em",
                          xl: "0.8em",
                          sm: "0.6em",
                          lg: "0.8em",
                        },
                        p: "2px",
                      }}
                      align="left"
                    >
                      {actionButtons?.map((button, buttonIndex) => (
                        <React.Fragment key={buttonIndex}>
                          {button.icon && (
                            <IconButton
                              onClick={() => button.onClick?.(rowIndex)}
                              sx={{ "&:hover": { background: "none" } }}
                              disabled={button.disabled?.(rowIndex)}
                            >
                              {/* Check if icon is a function or a ReactNode */}
                              {typeof button.icon === "function"
                                ? button.icon(rowIndex)
                                : button.icon}
                            </IconButton>
                          )}
                        </React.Fragment>
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              })}
            </TableBody>
          )}
        </Table>
        {totalRows > 0 && (
          <TablePagination
            sx={{
              fontSize: {
                md: "0.6em",
                xs: "0.6em",
                xl: "0.8em",
                sm: "0.6em",
                lg: "0.8em",
              },
              position: "sticky",
              bottom: 0,
              zIndex: 1,
              background: "white",
              height: "100%",
              p: 0,
              backgroundColor: "#077DD3",
              color: "#fff",
              ".css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar": {
                minHeight: "20px",
              },
            }}
            rowsPerPageOptions={[100,150,200]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage || (() => { })}
            onRowsPerPageChange={handleChangeRowsPerPage || (() => { })}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default DynamicTable;
