import { Autocomplete, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, IconButton, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, Typography, Tabs, Tab } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DynamicTable from "../../../../Dynamic-table";
import { MedicineDose } from "../../../../OPD/add-patient-record/MedicineDoses";
import dayjs from "dayjs";
import CloseIcon from '@mui/icons-material/Close';
import StopIcon from '@mui/icons-material/Stop';
import InfoIcon from "@mui/icons-material/Info";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { isDateTimeGreaterThan30MinutesAgo } from "../../../../../utils/dateTimeUtils";

interface TreatmentProps {
    onChange: (data: any) => void;
    data: any;
    otherTabsData: any;
    profile: any
}
const TreamentChart: React.FC<TreatmentProps> = ({ onChange, data, otherTabsData, profile }) => {
    const currentDate = dayjs();
    const [services, setServices] = useState<any[]>([])
    const [id, setId] = useState("")
    const [routeAdm, setRouteAdm] = useState<any[]>([]);
    const [formData, setFormData] = useState<any>({
        medicines: [],
        transfusion: [],
        stopped_medicines: []
    })
    const [openStopDialog, setOpenStopDialog] = useState<boolean>(false);
    const [medicineToStop, setMedicineToStop] = useState<any>(null);
    const [openDelMed, setOpenDelMed] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<any>(0);

    const handleCloseStopDialog = () => {
        setOpenDelMed(false)
    }
    const handleOpenStopDialog = () => {
        setOpenDelMed(true)
    }
    const handleTabChange = (event: any, newValue: any) => {
        if (newValue < formData?.stopped_medicines?.length) {
            setSelectedTab(newValue);
        } else {
            setSelectedTab(0);
        }
    };

    const mergeMedicines = (existingMedicines = [], newMedicines = [], stopped_medicines = []) => {
        const existingMedicinesMap = new Map(existingMedicines?.map((medicine: any) => [medicine?.id, medicine]));
        const deletedMedicineIds = new Set(stopped_medicines?.map((medicine: any) => medicine?.id));
        newMedicines?.forEach((newMedicine: any) => {
            if (deletedMedicineIds?.has(newMedicine.id)) {
                return;
            }
            const existingMedicine = existingMedicinesMap?.get(newMedicine.id);

            if (existingMedicine) {
                existingMedicinesMap?.set(newMedicine?.id, { ...existingMedicine, ...newMedicine });
            } else {
                existingMedicinesMap?.set(newMedicine.id, newMedicine);
            }
        });
        existingMedicinesMap?.forEach((medicine, id) => {
            if (deletedMedicineIds?.has(id)) {
                existingMedicinesMap?.delete(id);
            }
        });
        return Array.from(existingMedicinesMap?.values());
    };

    const mergeTransfusions = (existingTransfusions = [], newTransfusions = [], stoppedTransfusions = []) => {
        const existingTransfusionsMap = new Map(existingTransfusions?.map((transfusion: any) => [transfusion?.id, transfusion]));
        const deletedTransfusionIds = new Set(stoppedTransfusions?.map((transfusion: any) => transfusion?.id));
        newTransfusions?.forEach((newTransfusion: any) => {
            if (deletedTransfusionIds?.has(newTransfusion.id)) {
                return;
            }
            const existingTransfusion = existingTransfusionsMap?.get(newTransfusion.id);
            if (existingTransfusion) {
                existingTransfusionsMap?.set(newTransfusion?.id, { ...existingTransfusion, ...newTransfusion });
            } else {
                existingTransfusionsMap?.set(newTransfusion.id, newTransfusion);
            }
        });
        existingTransfusionsMap?.forEach((transfusion, id) => {
            if (deletedTransfusionIds?.has(id)) {
                existingTransfusionsMap?.delete(id);
            }
        });
        return Array.from(existingTransfusionsMap?.values());
    };

    const fetchFormData = async (formName: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${otherTabsData?._id}?formName=${formName}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            const fetchedData = await response?.data?.data?.formData;
            setFormData((prevData: any) => ({
                ...prevData,
                medicines: mergeMedicines(prevData?.medicines || [], fetchedData?.medicines || []),
                transfusion: mergeTransfusions(prevData?.transfusion, fetchedData?.transfusion),
            }));
            setId(response?.data?.data?.searchId);
        } catch (error) {
            toast.error('Failed to fetch form data.');
        }
    };

    useEffect(() => {
        fetchFormData('Treatment');
    }, []);

    useEffect(() => {
        if (data) {
            setFormData((prevState: any) => ({
                ...prevState,
                medicines: mergeMedicines(prevState?.medicines || [], data?.medicines || [], data?.stopped_medicines || []),
                transfusion: mergeTransfusions(prevState?.transfusion, data?.transfusion),  // Merge transfusions
                stopped_medicines: data?.stopped_medicines || [],
            }));
        }
    }, [data]);

    useEffect(() => {
        onChange({ ...formData, transfusion: formData?.transfusion })
    }, [formData?.transfusion])

    const getAllocatedServices = async (id: any) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_IPD_API_URL}/ipd-services-allocation/getAll_allocateServices/${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            if (response?.data?.isSuccess) {
                setServices(response?.data?.data || []);
            }
        } catch (error) {
            console.error("Error fetching admission information:", error);
        }
    }
    useEffect(() => {
        if (id) {
            getAllocatedServices(id)
        }
    }, [id]);

    const handleDeleteGivenBy = (medicineName: string, idx: number) => {
        if (!formData || !formData?.medicines) return;
        const updatedMedicines = formData?.medicines?.map((med: any) => {
            if (med?.name === medicineName) {
                const updatedGivenBy = med?.med_History?.filter((_: any, index: number) => index !== idx);
                return {
                    ...med,
                    med_History: updatedGivenBy,
                };
            }
            return med;
        });
        setFormData((prevState: any) => ({
            ...prevState,
            medicines: updatedMedicines,
        }));
    };

    const getRouteAdm = async () => {
        const response = await axios.get(
            `${process.env.REACT_APP_OPD_API_URL}/routes-of-administration/getAllByPagination`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                params: {
                    count: 50000,
                    pageNo: 1,
                },
            }
        );
        setRouteAdm(response.data?.data);
    };

    useEffect(() => {
        getRouteAdm();
    }, []);

    const formatDateTime = (dateTime: string) => {
        if (!dateTime) return { date: 'No date available', time: 'No time available' };
        const dayjsDate = dayjs(dateTime, 'DD.MM.YYYY hh:mm A');
        return {
            date: dayjsDate.format('DD.MMM.YYYY'),
            time: dayjsDate.format('hh:mm A'),
        };
    };


    useEffect(() => {
        onChange({ ...formData, medicines: formData?.medicines, transfusion: data?.transfusion });
    }, [formData])

    const handleContinue = (medicine: any) => {
        const doctorName = profile?.data?.fullName;
        const startedAt = currentDate.format('DD.MM.YYYY hh:mm A');
        const updatedMedicines = formData?.medicines?.map((med: any) => {
            if (med?.name === medicine?.name) {
                if (med?.status === 'started') return med;
                const updatedStatusHistory = [
                    ...(med?.med_History || []),
                    {
                        status: 'started',
                        date_Time: startedAt,
                        doctorName,
                        action: 'started',
                    },
                ];
                return {
                    ...med,
                    status: 'started',
                    med_History: updatedStatusHistory,
                };
            }
            return med;
        });
        setFormData({ ...formData, medicines: updatedMedicines });
    };

    const handleGivenBy = (medicine: MedicineDose) => {
        const doctorName = profile?.data?.fullName;
        const givenAt = currentDate.format('DD.MM.YYYY hh:mm A');

        const updatedMedicines = formData?.medicines?.map((med: any) => {
            if (med?.name === medicine?.name) {
                const updatedStatusHistory = [
                    ...(med?.med_History || []),
                    {
                        status: 'given',
                        date_Time: givenAt,
                        doctorName,
                        action: 'given',
                    },
                ];
                return {
                    ...med,
                    med_History: updatedStatusHistory,
                };
            }
            return med;
        });
        setFormData({ ...formData, medicines: updatedMedicines });
    };

    const handleStopClick = (medicine: any) => {
        setMedicineToStop(medicine); // Set the medicine to stop
        setOpenStopDialog(true); // Open the confirmation dialog
    };
    const handleStopMedicine = () => {
        if (!medicineToStop) return;

        const doctorName = profile?.data?.fullName;
        const stoppedAt = currentDate.format('DD.MM.YYYY hh:mm A');

        // Update the medicine history with stopped status
        const updatedMedicines = formData?.medicines?.map((med: any) => {
            if (med?.name === medicineToStop?.name) {
                const updatedStatusHistory = [
                    ...(med?.med_History || []),
                    {
                        status: 'stopped',
                        date_Time: stoppedAt,
                        doctorName,
                        action: 'stopped',
                    },
                ];
                return {
                    ...med,
                    status: 'stopped',
                    med_History: updatedStatusHistory,
                };
            }
            return med;
        });

        // Add to deleted medicines and remove from the medicines list
        const updatedDeletedMedicines = [
            ...(formData?.stopped_medicines || []),
            { ...medicineToStop, stoppedAt, doctorName },
        ];

        setFormData({
            ...formData,
            medicines: updatedMedicines.filter((med: any) => med?.name !== medicineToStop?.name),
            stopped_medicines: updatedDeletedMedicines,
        });

        setOpenStopDialog(false); // Close the dialog after stopping the medicine
        setMedicineToStop(null); // Clear the medicine to stop
    };

    const handleCancelStop = () => {
        setOpenStopDialog(false); // Close the dialog without doing anything
        setMedicineToStop(null); // Clear the medicine to stop
    };
    const [stopReasonDialogOpen, setStopReasonDialogOpen] = useState(false);
    const [reason, setReason] = useState("");
    const [transfusionToStop, setTransfusionToStop] = useState<any>(null);
    const [selectedTransfusion, setSelectedTransfusion] = useState<any>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const handleStartTransfusion = (transfusion: any) => {
        const doctorName = profile?.data?.fullName;
        const startedAt = dayjs().format("DD.MM.YYYY hh:mm A");
        const updatedTransfusions = formData?.transfusion?.map((item: any) => {
            if (item?.bloodTransfusion === transfusion?.bloodTransfusion) {
                const updatedHistory = [
                    ...(item?.transfuse_History || []),
                    { status: "started", date_Time: startedAt, doctorName },
                ];
                return {
                    ...item,
                    status: "started",
                    transfuse_History: updatedHistory,
                    lastAdvised: {
                        action: "Started",
                        date_Time: startedAt,
                        doctorName,
                    },
                };
            }
            return item;
        });
        setFormData({ ...formData, transfusion: updatedTransfusions });
        onChange({ ...formData, transfusion: updatedTransfusions });
    };

    const handleStopTransfusion = (transfusion: any) => {
        setTransfusionToStop(transfusion);
        setStopReasonDialogOpen(true); // Open the dialog
    };
    const handleConfirmStop = () => {
        const doctorName = profile?.data?.fullName;
        const stoppedAt = dayjs().format("DD.MM.YYYY hh:mm A");
        const updatedTransfusions = formData?.transfusion?.map((item: any) => {
            if (item?.bloodTransfusion === transfusionToStop?.bloodTransfusion) {
                const updatedHistory = [
                    ...(item?.transfuse_History || []),
                    { status: "stopped", date_Time: stoppedAt, doctorName, reason },
                ];
                return {
                    ...item,
                    status: "stopped",
                    transfuse_History: updatedHistory,
                    lastAdvised: {
                        action: "Stopped",
                        date_Time: stoppedAt,
                        doctorName,
                        reason,
                    },
                };
            }
            return item;
        });
        setFormData({ ...formData, transfusion: updatedTransfusions });
        onChange({ ...formData, transfusion: updatedTransfusions });
        setStopReasonDialogOpen(false);
        setReason("");
    };

    const handleOpenDialog = (transfusion: any) => {
        setSelectedTransfusion(transfusion);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedTransfusion(null);
    };

    // Helper function to update "Last Advised" dynamically
    const updateLastAdvised = (transfusion: any, status: string) => {
        const updatedTransfusions = formData?.transfusion?.map((item: any) => {
            if (item?.bloodTransfusion === transfusion?.bloodTransfusion) {
                return {
                    ...item,
                    lastAdvised: `${status} At: ${currentDate.format('DD.MM.YYYY hh:mm:ss A')} By: ${profile?.data?.fullName}`,
                };
            }
            return item;
        });
        setFormData({ ...formData, transfusion: updatedTransfusions });
    };
    useEffect(() => {
        onChange({ ...formData });
    }, [formData]);

    return (
        <>
            <Box sx={{ bgcolor: "rgba(223, 234, 255, 1)", border: "1px solid rgba(5, 95, 252, 1)", padding: "1px", borderRadius: "10px" }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Guideline
                </Typography>
                {formData?.guidance ? <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>{formData?.guidance}
                </Typography > : ""
                }
            </Box>
            <Typography sx={{ fontSize: '14px', fontWeight: '600', mt: 1 }} >Services</Typography>
            <DynamicTable
                headers={[
                    "SN",
                    "Services",
                    "Charge Type",
                    "Charges",
                    "Discount",
                    "Net Charges",
                ]}
                data={services?.map((item: any, index: any) => [
                    index + 1,
                    item?.ipdServices?.serviceName,
                    item?.ipdServices?.chargeType,
                    item?.ipdServices?.charges,
                    item?.discountRS,
                    item?.ipdServices?.charges - item?.discountRS,
                ])}
            />
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: '14px', fontWeight: '600', mt: 1 }}>Medicine</Typography>
                <Button variant="contained" color='secondary' onClick={handleOpenStopDialog} size="small" >Stopped Medicines</Button>
            </Box>
            <DynamicTable
                headers={[
                    "SN",
                    "Medicine",
                    "Strength",
                    "Unit",
                    "Dosage",
                    "Route",
                    "Frequency",
                    "Duration",
                    "Doctor Name",
                    "Date & Time"
                ]}
                data={formData?.medicines?.map((item: any, index: any) => [
                    index + 1,
                    item?.name,
                    item?.strength,
                    item?.medicineUnit,
                    item?.dosage,
                    (() => {
                        const route = routeAdm?.find(
                            (route: any) =>
                                route?._id === item?.routAdministrationId
                        );
                        return route ? route?.RA_Abbreviation : "-";
                    })(),
                    item?.frequency?.abr_notation,
                    item?.duration,
                    item?.doctorName || "-",
                    item?.dateTime || dayjs(item?.dateAndTime).format('DD.MM.YYYY hh:mm A')
                ])}
            />
            <Grid container>
                <Grid item md={12} sm={12} xs={12} sx={{ mt: 1 }}>
                    {Array.isArray(formData?.medicines) && formData?.medicines?.length > 0 && (
                        <Box display="flex" gap={2} flexWrap="wrap">
                            {Array.isArray(formData?.medicines) && formData?.medicines?.map((medicine: any, index: any) => {
                                if (!medicine) return null;
                                return (
                                    <Box
                                        key={index}
                                        sx={{
                                            backgroundColor: '#F5F5FB',
                                            padding: '8px',
                                            borderRadius: '8px',
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                            width: '180px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            maxHeight: '200px',
                                            overflowY: 'auto',
                                            '&::-webkit-scrollbar': {
                                                width: '4px',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: '#888',
                                                borderRadius: '10px',
                                            },
                                            '&::-webkit-scrollbar-thumb:hover': {
                                                backgroundColor: '#555',
                                            },
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                color: '#077DD3',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            {medicine?.name}
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                {medicine?.status === 'started' ? (
                                                    <Tooltip placement="top" title="Stop">
                                                        <IconButton
                                                            size="small"
                                                            // color="primary"
                                                            onClick={() => handleStopClick(medicine)}
                                                        >
                                                            <StopIcon fontSize="small" sx={{ color: 'red' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip placement="top" title="Start" >
                                                        <IconButton
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => handleContinue(medicine)}
                                                        >
                                                            <PlayArrowIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        </Typography>
                                        <Divider sx={{ width: '100%', mb: 0.2 }} />
                                        {/* <Box mt={0.5} mb={0.5}>
                                            {
                                                Array.isArray(medicine?.med_History) && medicine?.med_History?.map((item: any, idx: any) => {
                                                    const { date, time } = formatDateTime(item?.date_Time);
                                                    return <>
                                                        <Box >
                                                            <Box display="flex" alignItems="center" gap={2}>
                                                                <span style={{ fontSize: '10px', fontWeight: '600' }}>{idx + 1}:</span>
                                                                <Typography sx={{
                                                                    fontSize: '10px', color: item?.status === 'given' ? '#000' : item?.status === 'started' ? 'blue' : item?.status === 'stopped' ? 'red' : 'gray', textTransform: 'capitalize'
                                                                }}>
                                                                    {item?.status} at: {time}
                                                                </Typography>
                                                                {item?.status === 'given' && item?.doctorName === profile?.data?.fullName && (
                                                                    <Tooltip title="Delete" placement="top">
                                                                        <IconButton
                                                                            size="small"
                                                                            disabled={!isDateTimeGreaterThan30MinutesAgo(item?.date_Time)}
                                                                            onClick={() => handleDeleteGivenBy(medicine?.name, idx)}
                                                                            sx={{ color: 'red', height: 12, width: 12 }}
                                                                        >
                                                                            <CloseIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                            </Box>
                                                            <Typography sx={{ fontSize: '10px', color: 'gray', marginTop: 0.3 }}>
                                                                {item?.doctorName}
                                                                <span style={{ fontSize: '10px', color: 'gray' }}> {date} </span>
                                                            </Typography>
                                                        </Box>
                                                        <Divider sx={{ width: '100%', mb: 0.2 }} />
                                                    </>
                                                })
                                            }
                                            <Box mt={1} display="flex" justifyContent="center">
                                                {medicine?.status === 'started' && (
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => handleGivenBy(medicine)}
                                                        sx={{ mt: 'auto', color: '#fff' }}
                                                        color="success"
                                                    >
                                                        Given
                                                    </Button>
                                                )}
                                            </Box>
                                        </Box> */}
                                        <Box mt={0.5} mb={0.5}>
                                            {
                                                Array.isArray(medicine?.med_History) && medicine?.med_History
                                                    ?.map((item: any, idx: any) => {
                                                        const { date, time } = formatDateTime(item?.date_Time);

                                                        // Initialize the displayIndex to keep track of the index for "given" items
                                                        let displayIndex = null;

                                                        // Create a new counter for "given" statuses
                                                        const givenItems = medicine?.med_History?.filter((item: any) => item?.status === 'given');
                                                        const givenIndex = givenItems?.findIndex((givenItem: any) => givenItem === item);

                                                        // Only show index if the status is "given"
                                                        if (item?.status === 'given') {
                                                            displayIndex = givenIndex + 1; // Index starts from 1 for "given"
                                                        }

                                                        return (
                                                            <>
                                                                <Box>
                                                                    <Box display="flex" alignItems="center" gap={2}>
                                                                        {/* Show index only for "given" status */}
                                                                        {displayIndex !== null && (
                                                                            <span style={{ fontSize: '10px', fontWeight: '600' }}>
                                                                                {displayIndex}:
                                                                            </span>
                                                                        )}
                                                                        <Typography sx={{
                                                                            fontSize: '10px',
                                                                            color: item?.status === 'given' ? '#000' : item?.status === 'started' ? 'blue' : item?.status === 'stopped' ? 'red' : 'gray',
                                                                            textTransform: 'capitalize'
                                                                        }}>
                                                                            {item?.status} at: {time}
                                                                        </Typography>
                                                                        {item?.status === 'given' && item?.doctorName === profile?.data?.fullName && (
                                                                            <Tooltip title="Delete" placement="top">
                                                                                <IconButton
                                                                                    size="small"
                                                                                    disabled={!isDateTimeGreaterThan30MinutesAgo(item?.date_Time)}
                                                                                    onClick={() => handleDeleteGivenBy(medicine?.name, idx)}
                                                                                    sx={{ color: 'red', height: 12, width: 12 }}
                                                                                >
                                                                                    <CloseIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                    </Box>
                                                                    <Typography sx={{ fontSize: '10px', color: 'gray', marginTop: 0.3 }}>
                                                                        {item?.doctorName}
                                                                        <span style={{ fontSize: '10px', color: 'gray' }}> {date} </span>
                                                                    </Typography>
                                                                </Box>
                                                                <Divider sx={{ width: '100%', mb: 0.2 }} />
                                                            </>
                                                        );
                                                    })
                                            }
                                            <Box mt={1} display="flex" justifyContent="center">
                                                {medicine?.status === 'started' && (
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => handleGivenBy(medicine)}
                                                        sx={{ mt: 'auto', color: '#fff' }}
                                                        color="success"
                                                    >
                                                        Given
                                                    </Button>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Box mt={1}>
                <DynamicTable
                    headers={[
                        "SN",
                        "Blood Transfusion",
                        "Pint",
                        "Quantity",
                        "Components",
                        "Frequency",
                        "Duration",
                        "Doctor Name",
                        "Date & Time",
                        "Last Advised",
                        "Action",
                    ]}
                    data={Array.isArray(formData?.transfusion)
                        ? formData?.transfusion?.map((transfusion: any, index: any) => [
                            index + 1,
                            transfusion?.bloodTransfusion,
                            transfusion?.pint,
                            `${transfusion?.quantity || ""} ${transfusion?.quantity ? "ml" : "-"}`,
                            transfusion?.components?.join(", "),
                            transfusion?.frequency?.abr_notation || "-",
                            transfusion?.duration || "-",
                            transfusion?.doctorName,
                            transfusion?.dateTime,
                            transfusion?.lastAdvised ? (
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: "0.8rem",
                                            color: "#333",
                                        }}
                                    >
                                        {transfusion?.lastAdvised?.action} At: {transfusion?.lastAdvised?.date_Time}
                                    </Typography>
                                    <Typography

                                        sx={{
                                            fontSize: "0.65rem",
                                            color: "#555",
                                        }}
                                    >
                                        {transfusion?.lastAdvised.doctorName}
                                    </Typography>
                                </Box>
                            ) : (
                                "-"
                            ),
                            <Box display="flex" alignItems="center" gap={1} >
                                <Tooltip placement="top" title={transfusion?.status === "started" ? "Stop" : "Start"}>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            transfusion?.status === "started"
                                                ? handleStopTransfusion(transfusion)
                                                : handleStartTransfusion(transfusion)
                                        }
                                    >
                                        {transfusion?.status === "started" ? (
                                            <StopIcon fontSize="small" sx={{ color: "red" }} />
                                        ) : (
                                            <PlayArrowIcon fontSize="small" sx={{ color: 'green' }} />
                                        )}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Detail">
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={() => handleOpenDialog(transfusion)}
                                    >
                                        <InfoIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Box>,
                        ])
                        : []}
                />
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    fullWidth
                    maxWidth="sm"
                    sx={{
                        '& .MuiDialog-paper': {
                            borderRadius: '12px',
                            padding: '16px',
                            width: '300px'

                        },
                    }}
                >
                    <DialogTitle
                        sx={{
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                            borderBottom: '1px solid #e0e0e0',
                            paddingBottom: '8px',
                        }}
                    >
                        Detail
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseDialog}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {selectedTransfusion ? (
                            <Box>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        marginBottom: '16px',
                                    }}
                                >
                                    {selectedTransfusion?.bloodTransfusion}
                                </Typography>
                                {selectedTransfusion?.transfuse_History?.map((history: any, index: number) => (
                                    <Box
                                        key={index}
                                        mb={2}
                                        sx={{
                                            backgroundColor: '#f9f9f9',
                                            borderRadius: '8px',
                                            padding: '8px 12px',
                                            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '0.75rem',

                                                color: '#333',
                                            }}
                                        >
                                            {history.status === 'started' ? 'Started at' : 'Stopped at'}: {history?.date_Time}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '0.7rem',
                                                color: '#666',
                                            }}
                                        >
                                            By: {history.doctorName}
                                        </Typography>
                                        {history.reason && (
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: '0.6rem',
                                                    color: '#999',
                                                    fontStyle: 'italic',
                                                    marginTop: '4px',
                                                }}
                                            >
                                                Reason: {history.reason}
                                            </Typography>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        ) : (
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: 'center',
                                    fontSize: '0.9rem',
                                    color: '#999',
                                }}
                            >
                                No details available.
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions
                        sx={{
                            justifyContent: 'center',
                            paddingTop: '8px',
                        }}
                    >
                        <Button
                            onClick={handleCloseDialog}
                            sx={{
                                fontSize: '0.8rem',
                                color: '#007bff',
                                textTransform: 'capitalize',
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={stopReasonDialogOpen} onClose={() => setStopReasonDialogOpen(false)}>
                    <DialogTitle>Please enter the Reason</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Reason"
                            type="text"
                            fullWidth
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setStopReasonDialogOpen(false)}
                            sx={{
                                backgroundColor: "red",
                                color: "white",
                                "&:hover": {
                                    backgroundColor: "darkred",
                                },
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            onClick={handleConfirmStop}

                            sx={{
                                fontSize: '0.85rem',
                                color: '#007bff',
                                textTransform: 'capitalize',
                            }}
                        >
                            Confirm
                        </Button>

                    </DialogActions>
                </Dialog>
            </Box>
            <Dialog open={openStopDialog}>
                <DialogContent>
                    <Typography>Are you sure you want to stop this medicine?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelStop} color="primary">
                        No
                    </Button>
                    <Button onClick={handleStopMedicine} variant="contained" color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDelMed} onClose={handleCloseStopDialog} maxWidth="md" fullWidth>
                <DialogContent>
                    <Box>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: '13px' }}>
                            Stopped Medicines History
                        </Typography>
                        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Deleted Medicines" variant={"scrollable"}>
                            {formData?.stopped_medicines && Array.isArray(formData?.stopped_medicines) && formData?.stopped_medicines.length > 0 ? (
                                formData?.stopped_medicines.map((medicine: any, index: any) => (
                                    <Tab key={index} label={medicine?.name} />
                                ))
                            ) : (
                                <Typography>No Stopped Medicines Available</Typography>
                            )}
                        </Tabs>

                        {/* Check if the selectedTab is within the bounds of stopped_medicines */}
                        {formData?.stopped_medicines && formData?.stopped_medicines.length > 0 && formData?.stopped_medicines[selectedTab] && (
                            <Box p={1}>
                                {/* <Typography variant="h6" gutterBottom>
                                    Medicine History for: {formData?.stopped_medicines[selectedTab]?.name}
                                </Typography> */}
                                {Array.isArray(formData?.stopped_medicines[selectedTab]?.med_History) &&
                                    formData?.stopped_medicines[selectedTab]?.med_History?.length > 0 ? (
                                    formData?.stopped_medicines[selectedTab]?.med_History?.map((item: any, idx: any) => {
                                        return (
                                            <Box key={idx}>
                                                <Box display="flex" alignItems="center" gap={2}>
                                                    <span style={{ fontSize: '10px', fontWeight: '600' }}>{idx + 1}:</span>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '10px',
                                                            color: item?.status === 'stopped' ? 'red' : 'gray',
                                                            textTransform: 'capitalize',
                                                        }}
                                                    >
                                                        {item?.status} By {item?.doctorName} at: {item?.date_Time}
                                                    </Typography>
                                                </Box>
                                                <Divider sx={{ width: '100%', mb: 0.2 }} />
                                            </Box>
                                        );
                                    })
                                ) : (
                                    <Typography sx={{ fontSize: '10px', color: 'gray' }}>No History Available</Typography>
                                )}
                            </Box>
                        )}
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseStopDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default TreamentChart;

