import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    TextField,
    IconButton,
    Grid,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Done,
    Close,
    Search as SearchIcon,
} from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DynamicTable from "../../Dynamic-table";
import { useNavigate } from "react-router-dom";


interface OptionItem {
    _id: string;
    optionName: string;
    scheduleNotation: string[];
}

const CheckedOption: React.FC = () => {
    const [options, setOptions] = useState<OptionItem[]>([]);
    const [newOption, setNewOption] = useState<string>("");
    const [editOption, setEditOption] = useState<string>("");
    const [editingId, setEditingId] = useState<string | null>(null);
    const [scheduleNotation, setScheduleNotation] = useState<string[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(100);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchInput, setSearchInput] = useState<string>("");
    const navigate = useNavigate();
    // ** Fetch Options API with Pagination and Search **
    const getOptions = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/checked-status-options/getAllByPagination`,
                {
                    params: {
                        pageNo: page + 1,
                        count: rowsPerPage,
                        search: searchInput, // Added search
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            if (response.data.isSuccess) {
                setOptions(response.data.data);
                setTotalCount(response.data.totalCount);
            } else {
                toast.error("Failed to fetch options");
            }
        } catch (error) {
            toast.error("Error fetching options");
        }
    };

    useEffect(() => {
        getOptions(); // Fetch options on load and whenever search, page, or rowsPerPage changes
    }, [page, rowsPerPage, searchInput]);

    const resetForm = () => {
        setNewOption("");
        setEditOption("");
        setEditingId(null);
        setScheduleNotation([]);
    };

    const addOption = async () => {
        if (newOption.trim() && scheduleNotation.length > 0) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/checked-status-options/addCheckedStatusOption`,
                    {
                        optionName: newOption,
                        scheduleNotation,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                if (response.data.isSuccess) {
                    toast.success("Option added successfully");
                    getOptions();
                    resetForm();
                } else {
                    toast.error("Failed to add option");
                }
            } catch (error) {
                toast.error("Error adding option");
            }
        } else {
            toast.error("Please fill in all fields");
        }
    };

    const updateOption = async () => {
        if (editingId && editOption.trim()) {
            try {
                const response = await axios.patch(
                    `${process.env.REACT_APP_BASE_URL}/checked-status-options/updateById/${editingId}`,
                    {
                        optionName: editOption,
                        scheduleNotation,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                if (response.data.isSuccess) {
                    toast.success("Option updated successfully");
                    getOptions();
                    resetForm();
                } else {
                    toast.error("Failed to update option");
                }
            } catch (error) {
                toast.error("Error updating option");
            }
        } else {
            toast.error("Please fill in all fields");
        }
    };

    const deleteOption = async (id: string) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/checked-status-options/deleteById/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            if (response.data.isSuccess) {
                toast.success("Option deleted successfully");
                getOptions();
            } else {
                toast.error("Failed to delete option");
            }
        } catch (error) {
            toast.error("Error deleting option");
        }
    };

    const handleEdit = (id: string, optionName: string, notations: string[]) => {
        setEditingId(id);
        setEditOption(optionName);
        setScheduleNotation(notations);
    };

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 3,
                }}
            >
                <Typography variant="h5" fontWeight="bold">
                    Manage Disposal Option
                </Typography>
                <TextField
                    label="Search"
                    placeholder="Search Disposal Options..."
                    variant="outlined"
                    value={searchInput}
                    onChange={handleSearchInputChange}
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        ),
                    }}
                />
                {/* <CancelOutlinedIcon
            onClick={() => navigate("/access-config")}
            sx={{ cursor: "pointer" }}
          /> */}
            </Box>

            <Grid container spacing={3}>
                {/* Left Form Section */}
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
                            {editingId ? "Edit Disposal Option" : "Add New Disposal Option"}
                        </Typography>
                        <TextField
                            label="Disposal Option"
                            value={editingId ? editOption : newOption}
                            onChange={(e) =>
                                editingId ? setEditOption(e.target.value) : setNewOption(e.target.value)
                            }
                            fullWidth
                            size="small"
                            sx={{ mb: 2 }}
                        />
                        <FormControl fullWidth sx={{ mb: 2 }} size="small">
                            <InputLabel>Select Notations</InputLabel>
                            <Select
                                multiple
                                value={scheduleNotation || []}
                                onChange={(e) => setScheduleNotation(e.target.value as string[])}
                                renderValue={(selected) =>
                                    selected.length > 0
                                        ? selected
                                            .map((val) => (val === "Abr." ? "Medical" : val)) // Display "Medical" instead of "Abr."
                                            .join(", ")
                                        : "None"
                                }
                            >
                                {["Abr.", "Math", "Urdu", "English"].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        <Checkbox checked={(scheduleNotation || []).includes(option)} />
                                        <ListItemText primary={option === "Abr." ? "Medical" : option} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Button
                            variant="contained"
                            fullWidth
                            onClick={editingId ? updateOption : addOption}
                            startIcon={<Done />}
                        >
                            {editingId ? "Update" : "Save"}
                        </Button>
                    </Paper>
                </Grid>

                {/* Right Table Section */}
                <Grid item xs={12} md={8}>
                    <DynamicTable
                        headers={["SN", "Disposal Option", "Notations", "Actions"]}
                        data={options.map((item, index) => [
                            page * rowsPerPage + index + 1,
                            item.optionName,
                            item.scheduleNotation?.join(", ").replaceAll("Abr.", "Medical") || "N/A",
                        ])}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={(e, newPage) => setPage(newPage)}
                        handleChangeRowsPerPage={(e) => setRowsPerPage(parseInt(e.target.value, 100))}
                        totalRows={totalCount}
                        actionButtons={[
                            {
                                icon: <EditIcon sx={{ color: "#FFB800", cursor: "pointer" }} />,
                                onClick: (rowIndex: any) =>
                                    handleEdit(options[rowIndex]._id, options[rowIndex].optionName, options[rowIndex].scheduleNotation),
                            },
                            {
                                icon: <DeleteIcon sx={{ color: "#FF5454", cursor: "pointer" }} />,
                                onClick: (rowIndex: any) => deleteOption(options[rowIndex]._id),
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default CheckedOption;
