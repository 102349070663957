// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
// import { fetchProfileData } from './profileSlice';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';



// const setProfileData = (profileData) => ({
//   type: 'profile/setProfileData',
//   payload: profileData,
// });

// export const fetchDoctorsData = createAsyncThunk('fetchDoctorsData', async (_, { getState, dispatch }) => {
//   try {
//     const token = localStorage.getItem('token');
//     const headers = {
//       Authorization: `Bearer ${token}`
//     };
//     let url = '';
//     url = `${process.env.REACT_APP_BASE_URL}/users/getAssignedDoctores`;
//     const res = await axios.get(
//       url,
//       {
//         headers
//       }
//     );
//     // console.log(res.data, ' response data');
//     return res?.data;
//   } catch (error) {
//     console.log(error, ' error in userArticle');
//   }
// });

// const doctorSlice = createSlice({
//   name: 'doctorSlice',
//   initialState: {
//     isLoading: false,
//     isSuccess: false,
//     error: null,
//     profileData: null,
//     doctor: []
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchDoctorsData.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(fetchDoctorsData.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.isSuccess = true;
//         // console.log(action.payload, 'API response'); // Log the entire response
//         state.doctor = action.payload;
//         state.doctor = action.payload?.data;
//       })
//       .addCase(fetchDoctorsData.rejected, (state, action) => {
//         state.isLoading = false;
//         state.isSuccess = false;
//         state.error = action.error;
//       })
//       .addCase(fetchProfileData.fulfilled, (state, action) => {
//         state.profileData = action.payload?.data;
//       });
//   }
// });

// export default doctorSlice.reducer;
// export const { resetFetchArticleListState } = doctorSlice.actions;





import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchDoctorsData = createAsyncThunk(
  'fetchDoctorsData',
  async (_, { getState, dispatch }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const url = `${process.env.REACT_APP_BASE_URL}/users/getAssignedDoctores`;

      const res = await axios.get(url, { headers });
      return res.data;
    } catch (error) {
      // Here we handle AxiosError specifically and rethrow the message.
      console.error('Error occurred:', error);
      throw error;  // This will ensure the error gets passed to the rejected case in extraReducers
    }
  }
);

const doctorSlice = createSlice({
  name: 'doctorSlice',
  initialState: {
    isLoading: false,
    isSuccess: false,
    error: null,
    profileData: null,
    doctor: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDoctorsData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDoctorsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.doctor = action.payload?.data;  // Adjust to your API response structure
      })
      .addCase(fetchDoctorsData.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        // Here, we capture the error message from the rejected action
        state.error = action.error?.message || 'An unknown error occurred';
      });
  },
});

export default doctorSlice.reducer;
