import { Box, Button, Grid, TextField, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DeleteConfirmationModal from "../../../Modals/delete-modal";
import DynamicTable from "../../../Dynamic-table";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

const Dosage: React.FC<any> = () => {
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState<any>("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [dosageData, setDosageData] = useState<any>();
  const [searchInput, setSearchInput] = useState("");
  const [formData, setFormData] = useState({
    med_notation: "",
    eng_notation: "",
    urdu_notation: "",
  });

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_OPD_API_URL}/dosage/getAllByPagination`,
        {
          params: {
            pageNo: page + 1,
            count: rowsPerPage,
            search: searchInput,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDosageData(response.data?.data);
      setTotal(response.data?.totalCount);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (id: any, rowData: any) => {
    setEditData(id);
    setFormData({
      med_notation: rowData.med_notation,
      eng_notation: rowData.eng_notation,
      urdu_notation: rowData.urdu_notation,
    });
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, searchInput]);

  const handleSubmit = async () => {
    if (editData) {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_OPD_API_URL}/dosage/updateById/${editData}`,
          {
            exta: {},
            med_notation: formData.med_notation,
            eng_notation: formData.eng_notation,
            urdu_notation: formData.urdu_notation,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.data.isSuccess) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setFormData({
            med_notation: "",
            eng_notation: "",
            urdu_notation: "",
          });
          fetchData();
          setEditData(null);
        }
      } catch (error: any) {
        toast.error(error.message || "Something went wrong");
      }
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_OPD_API_URL}/dosage/addDosage`,
          {
            med_notation: formData.med_notation,
            eng_notation: formData.eng_notation,
            urdu_notation: formData.urdu_notation,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.data.isSuccess) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setFormData({
            med_notation: "",
            eng_notation: "",
            urdu_notation: "",
          });
          fetchData();
          setEditData(null);
        }
      } catch (error: any) {
        toast.error(error.message || "Something went wrong");
      }
    }
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteClick = (id: any) => {
    setIsDeleteModalOpen(true);
    setSelectedItemId(id);
  };

  const handleDeleteConfirm = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Authorization token is missing");
        return;
      }

      const response = await axios.delete(
        `${process.env.REACT_APP_OPD_API_URL}/dosage/deleteById/${selectedItemId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchData();
      toast.success(response.data.message);
      setFormData({
        med_notation: "",
        eng_notation: "",
        urdu_notation: "",
      });
      setEditData(null);
    } catch (error: any) {
      toast.error(error.message);
    }
    setIsDeleteModalOpen(false);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  return (
    <Box sx={{  }}>
      <Box
        sx={{
          background: "#f4f4f4",
          borderRadius: 2,
          padding: "1px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Dosage Management
        </Typography>
        <Box display="flex" alignItems="center" gap={1}>
          <TextField
            label="Search"
            placeholder="Search Notation..."
            variant="outlined"
            value={searchInput}
            onChange={handleSearchInputChange}
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon sx={{ color: "#055FFC" }} />
                </IconButton>
              ),
            }}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("/opd-admin")}
          >
            Cancel
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: 2,
              p: 3,
              boxShadow: 1,
            }}
          >
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
              {editData ? "Update Dosage" : "Add New Dosage"}
            </Typography>
            <TextField
              size="small"
              label="Med Notation"
              name="med_notation"
              value={formData.med_notation}
              fullWidth
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <TextField
              size="small"
              label="Eng Notation"
              name="eng_notation"
              value={formData.eng_notation}
              fullWidth
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <TextField
              size="small"
              label="Urdu Notation"
              name="urdu_notation"
              value={formData.urdu_notation}
              fullWidth
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              {editData ? "Update" : "Save"}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <DynamicTable
            headers={["SN", "Med Notation", "Eng Notation", "Urdu Notation", "Actions"]}
            data={dosageData?.map((item: any, index: any) => [
              page * rowsPerPage + index + 1,
              item?.med_notation,
              item?.eng_notation,
              item?.urdu_notation,
            ])}
            loading={false}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalRows={total}
            actionButtons={[
              {
                icon: (
                  <EditIcon sx={{ color: "#FFB800", cursor: "pointer" }} />
                ),
                onClick: (rowIndex: any) =>
                  handleEditClick(
                    dosageData[rowIndex]?._id,
                    dosageData[rowIndex]
                  ),
              },
              {
                icon: (
                  <DeleteIcon sx={{ color: "#FF5454", cursor: "pointer" }} />
                ),
                onClick: (rowIndex: any) =>
                  handleDeleteClick(dosageData[rowIndex]?._id),
              },
            ]}
          />
        </Grid>
      </Grid>

      <DeleteConfirmationModal
        open={isDeleteModalOpen}
        handleClose={handleDeleteModalClose}
        handleConfirm={handleDeleteConfirm}
      />
    </Box>
  );
};

export default Dosage;
