import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    Grid,
    MenuItem,
    TextField,
    Typography,
    FormControlLabel,
    Switch,
} from "@mui/material";
import DeleteConfirmationModal from '../../../Modals/delete-modal';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DynamicTable from '../../../Dynamic-table';
import { isDateTimeGreaterThan30MinutesAgo } from '../../../../utils/dateTimeUtils';

interface Vital {
    temperature: string;
    rr: string;
    hr: string;
    bp: string;
    weight: string;
    status: string;
    comment: string;
    date_time: string;
    doctor: string;
    complaint: string;
    detail: string;
}
interface FormData {
    vitals: Vital;
}
interface DailyProgressNotesProps {
    onChange: (data: any) => void;
    data: FormData;
    otherTabsData: any;
    profile: any;
    handleSave: (shouldClose: boolean, searchId?: any) => void;
}

const DailyProgressNotes: React.FC<DailyProgressNotesProps> = ({ onChange, data, otherTabsData, profile, handleSave }) => {
    const [formData, setFormData] = useState<FormData>({
        vitals: {
            temperature: '',
            rr: '',
            hr: '',
            bp: '',
            weight: '',
            status: '',
            comment: '',
            date_time: dayjs().format('DD.MM.YYYY hh:mm A'),
            doctor: profile?.data?.fullName || '',
            complaint: '',
            detail: '',
        },
    });
    const [savedVitals, setSavedVitals] = useState<any[]>([]); // Initialize as an empty array
    const [selectedVitalIndex, setSelectedVitalIndex] = useState<number | null>(null); // Track the selected vital for editing
    const [isMultiline, setIsMultiline] = useState(false);
    const [isComponentVisible, setIsComponentVisible] = useState(false); // Toggle state for visibility
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [clickSave, setClickSave] = useState<any>(false);
    const [searchId, setSearchId] = useState<any>();
    const handleDeleteClick = (index: number) => {
        setSelectedVitalIndex(index);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (selectedVitalIndex !== null) {
            const updatedVitals = savedVitals.filter(
                (_: any, i: number) => i !== selectedVitalIndex
            );
            setSavedVitals(updatedVitals);
            onChange({ vitals: updatedVitals });
        }
        setIsDeleteModalOpen(false);
        setSelectedVitalIndex(null);
    };

    const handleDeleteModalClose = () => {
        setIsDeleteModalOpen(false);
        setSelectedVitalIndex(null);
    };

    const handleChange21 = (field: keyof Vital, value: string) => {
        setFormData(prevData => ({
            ...prevData,
            vitals: { ...prevData.vitals, [field]: value },
        }));
    };

    const handleFocus = () => {
        setIsMultiline(true);
    };

    const handleBlur = () => {
        setIsMultiline(false);
    };

    useEffect(() => {
        if (data && Array.isArray(data.vitals)) {
            setSavedVitals(data.vitals);
        } else {
            setSavedVitals([]);
        }
    }, [data]);

    useEffect(() => {
        if (otherTabsData) {
            setSearchId(otherTabsData?._id)
        }
    }, [otherTabsData])
    
    const handleChange = (field: keyof Vital, value: string) => {
        const updatedVitals = { ...formData.vitals, [field]: value };
        setFormData(prevState => ({ ...prevState, vitals: updatedVitals }));
        onChange({ ...formData, vitals: updatedVitals });
    };

    const saveData = async () => {
        if (selectedVitalIndex !== null) {
            const updatedVitals = [...savedVitals];
            updatedVitals[selectedVitalIndex] = formData.vitals;
            setSavedVitals(updatedVitals);
            onChange({ vitals: updatedVitals });
            setSelectedVitalIndex(null);
        } else {
            setSavedVitals((prev: any[]) => {
                const updatedVitals = [...prev, formData.vitals];
                onChange({ vitals: updatedVitals });
                return updatedVitals;
            });
        }
        setClickSave(true)
        setFormData({
            vitals: {
                temperature: '',
                rr: '',
                hr: '',
                bp: '',
                weight: '',
                status: '',
                comment: '',
                date_time: dayjs().format('DD.MM.YYYY hh:mm:ss A'),
                doctor: profile?.data?.fullName || '',
                complaint: '',
                detail: '',
            },
        });
    };

    useEffect(() => {
        if (clickSave) {
            handleSave(false, searchId);
        }
    }, [clickSave])

    const deleteVital = (index: number) => {
        const updatedVitals = savedVitals.filter((_: any, i: any) => i !== index);
        setSavedVitals(updatedVitals);
        onChange({ vitals: updatedVitals });
    };
    const editVital = (index: number) => {
        const selectedVital = savedVitals[index];
        setFormData({ vitals: selectedVital });
        setSelectedVitalIndex(index); // Mark the index of the vital being edited
        setIsComponentVisible(true); // Ensure the Vitals section is visible
    };

    return (
        <>
            <Box
                sx={{
                    bgcolor: "rgba(233, 233, 237, 1)",
                    padding: 0.25,
                    textAlign: "center",
                    mt: 0.5,
                    mb: 0.5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '30px',
                }}
            >
                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                    Vitals and Complaints
                </Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isComponentVisible}
                            onChange={() => setIsComponentVisible(!isComponentVisible)}
                            color="primary"
                        />
                    }
                    label="+Add Vital"
                />
            </Box>

            {isComponentVisible && (
                <Box mt={1} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={1.4}>
                        <Grid item md={1.4} sm={2} xs={3}>
                            <Box display="flex" alignItems="center" className="search-field">
                                <TextField
                                    label="Temp" // Label for the input
                                    placeholder="92-107"
                                    size="small"
                                    value={formData.vitals.temperature}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d{0,3}(\.\d{0,1})?$/.test(value)) { // Allow only numbers and decimals
                                            handleChange('temperature', value);
                                        }
                                    }}
                                    fullWidth
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '10px',
                                            borderRadius: '8px',
                                            backgroundColor: "#F5F5FB",
                                            height: '30px',
                                        },
                                    }}
                                    error={
                                        !!formData.vitals.temperature &&
                                        (parseFloat(formData.vitals.temperature) < 92 || parseFloat(formData.vitals.temperature) > 107)
                                    }
                                    helperText={null} // No message is shown for invalid input
                                />
                                <Typography sx={{ padding: "10px", ml: 1, fontWeight: "500", fontSize: '12px' }}>
                                    F
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item md={1.4} sm={2} xs={3}>
                            <Box display="flex" alignItems="center" className="search-field">
                                <TextField
                                    label="RR" // Label for the input
                                    placeholder="1-80"
                                    size="small"
                                    value={formData?.vitals?.rr}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        // Check if input is a number within the range of 1 to 50
                                        if (/^\d*$/.test(value) && (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 80))) {
                                            handleChange('rr', value);
                                        }
                                    }}
                                    fullWidth
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '12px',
                                            borderRadius: '12px',
                                            backgroundColor: "#F5F5FB",
                                        },
                                    }}
                                    error={!!formData.vitals.rr && (parseInt(formData.vitals.rr) < 1 || parseInt(formData.vitals.rr) > 80)}
                                    helperText={
                                        formData.vitals.rr &&
                                            (parseInt(formData.vitals.rr) < 1 || parseInt(formData.vitals.rr) > 80)
                                            ? 'Please enter a value between 1 and 80'
                                            : ''
                                    }
                                />
                                <Typography sx={{ padding: "10px", ml: 1, fontWeight: "500", fontSize: '12px' }}>
                                    /Min
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item md={1.4} sm={2} xs={3}>
                            <Box display="flex" alignItems="center" className="search-field">
                                <TextField
                                    label="HR" // Label for the input
                                    placeholder="50-250"
                                    size="small"
                                    value={formData?.vitals?.hr}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d{0,3}$/.test(value)) { // Allow only up to 3 digits
                                            handleChange('hr', value);
                                        }
                                    }}
                                    fullWidth
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '12px',
                                            borderRadius: '12px',
                                            backgroundColor: "#F5F5FB",
                                        },
                                    }}
                                />
                                <Typography sx={{ padding: "10px", ml: 1, fontWeight: "500", fontSize: '12px' }}>
                                    /Min
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item md={1.6} sm={2} xs={3}>
                            <Box display="flex" alignItems="center" className="search-field">
                                <TextField
                                    label="BP"
                                    placeholder="120/80"
                                    size="small"
                                    value={formData?.vitals?.bp}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (!value) {
                                            handleChange('bp', '');
                                            return;
                                        }
                                        const regex = /^(\d{1,3})\/?(\d{0,3})$/;
                                        if (regex.test(value)) {
                                            const parts = value.split('/');
                                            const firstPart = parseInt(parts[0], 10);
                                            const secondPart = parts[1] ? parseInt(parts[1], 10) : 0;
                                            if (firstPart >= 1 && firstPart <= 300 && secondPart <= firstPart) {
                                                handleChange('bp', value);
                                            }
                                        }
                                    }}
                                    fullWidth
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '12px',
                                            borderRadius: '12px',
                                            backgroundColor: "#F5F5FB",
                                        },
                                    }}
                                />
                                <Typography sx={{ padding: "5px", fontWeight: "500", fontSize: '12px' }}>
                                    mmHg
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item md={1.4} sm={2} xs={3}>
                            <Box display="flex" alignItems="center" className="search-field">
                                <TextField
                                    label="SPO₂"
                                    placeholder="90-100"
                                    size="small"
                                    value={formData?.vitals?.weight}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d{0,3}$/.test(value)) {
                                            const numericValue = parseInt(value, 10);

                                            // Allow empty value for clearing the input field
                                            if (value === '' || (numericValue >= 1 && numericValue <= 100)) {
                                                handleChange('weight', value);
                                            }
                                        }
                                    }}
                                    fullWidth
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '12px',
                                            borderRadius: '12px',
                                            backgroundColor: "#F5F5FB",
                                        },
                                    }}
                                />
                                <Typography sx={{ padding: "10px", ml: 1, fontWeight: "500", fontSize: '12px' }}>
                                    %
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={1.4} sm={6} xs={12}>
                            <TextField
                                select
                                label="Status"
                                size="small"
                                value={formData.vitals.status}
                                onChange={(e) => handleChange('status', e.target.value)}
                                fullWidth
                                className='search-field'
                            >
                                <MenuItem value="">Select</MenuItem>
                                {['Improving', 'Not Improving', 'Deteriorated', 'Sick Critical', "Static"]?.map((option) => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item md={3} sm={6} xs={12}>
                            <TextField
                                placeholder="Complaint"
                                size="small"
                                value={formData.vitals.complaint}
                                onChange={(e) => handleChange('complaint', e.target.value)}
                                className='search-field'
                                fullWidth
                            />
                        </Grid>

                        <Grid item md={2} sm={6} xs={12}>
                            <TextField
                                placeholder="Detail"
                                size="small"
                                value={formData.vitals.detail}
                                onChange={(e) => handleChange('detail', e.target.value)}
                                className='search-field'
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <TextField
                                label="Comment"
                                placeholder="Comment"
                                value={formData.vitals.comment}
                                onChange={(e) => handleChange21('comment', e.target.value)}
                                fullWidth
                                size="small"
                                InputProps={{
                                    style: {
                                        borderRadius: "12px",
                                        background: "#F5F5FB",
                                        fontSize: "12px",
                                    },
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            border: "none",
                                        },
                                        "&:hover fieldset": {
                                            border: "none",
                                        },
                                        "&.Mui-focused fieldset": {
                                            border: "none",
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                        top: "-2px",
                                    },
                                }}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                multiline={isMultiline}
                                minRows={isMultiline ? 3 : 1}
                            />
                        </Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={saveData}
                            size="small"
                            disabled={
                                !formData?.vitals.temperature &&
                                !formData?.vitals.rr &&
                                !formData?.vitals.hr &&
                                !formData?.vitals.bp &&
                                !formData?.vitals.weight &&
                                !formData?.vitals.status &&
                                !formData?.vitals.comment &&
                                !formData?.vitals.complaint &&
                                !formData?.vitals.detail
                            }
                            sx={{
                                ml: 2,
                                mt: 1.5,
                                padding: '4px 8px',
                                fontSize: '12px',
                                minWidth: '50',
                                height: '30px',
                            }}
                        >
                            {selectedVitalIndex !== null ? 'Update' : 'Save'}
                        </Button>
                        <Divider sx={{ width: '100%', mt: 1, mb: 1 }} />
                    </Grid>
                </Box>
            )}
            <DynamicTable
                headers={[
                    "SN",
                    "Date",
                    "Temp",
                    "RR",
                    "HR",
                    "BP",
                    "SpO2",
                    "Status",
                    "Comment",
                    "Complaint",
                    "Detail",
                    "Created By",
                    "Action",
                ]}
                data={savedVitals?.map((vital: any, index: any) => [
                    index + 1,
                    vital.date_time,
                    vital.temperature,
                    vital.rr,
                    vital.hr,
                    vital.bp,
                    vital.weight,
                    vital.status,
                    vital.comment,
                    vital.complaint,
                    vital.detail,
                    vital.doctor,
                ])}
                actionButtons={[
                    {
                        icon: (rowIndex: number) => {
                            const isDisabled = !isDateTimeGreaterThan30MinutesAgo(savedVitals[rowIndex]?.date_time);
                            return (
                                <EditIcon
                                    sx={{
                                        color: isDisabled ? "#D3D3D3" : "#4CAF50",
                                        minWidth: "20.09px",
                                        minHeight: "20.09px",
                                        height: 20.09,
                                        width: 29.09,
                                        cursor: isDisabled ? "not-allowed" : "pointer",
                                        opacity: isDisabled ? 0.5 : 1,
                                        "&:hover": {
                                            background: "none",
                                            color: isDisabled ? "#D3D3D3" : "rgb(72, 141, 72)",
                                        },
                                    }}
                                />
                            );
                        },
                        onClick: (rowIndex: number) => {
                            const isDisabled = !isDateTimeGreaterThan30MinutesAgo(savedVitals[rowIndex]?.date_time);
                            if (!isDisabled) {
                                editVital(rowIndex);
                            }
                        },
                        disabled: (rowIndex: number) => !isDateTimeGreaterThan30MinutesAgo(savedVitals[rowIndex]?.date_time),
                    },
                    {
                        icon: (rowIndex: number) => (
                            <DeleteIcon
                                sx={{
                                    color: isDateTimeGreaterThan30MinutesAgo(savedVitals[rowIndex]?.date_time) ? "#FF5454" : "#D3D3D3",
                                    minWidth: "20.09px",
                                    minHeight: "20.09px",
                                    height: 20.09,
                                    width: 29.09,
                                    cursor: isDateTimeGreaterThan30MinutesAgo(savedVitals[rowIndex]?.date_time) ? "pointer" : "not-allowed",
                                    "&:hover": {
                                        background: "none",
                                        color: isDateTimeGreaterThan30MinutesAgo(savedVitals[rowIndex]?.date_time) ? "rgb(191, 44, 44)" : "#D3D3D3",
                                    },
                                }}
                            />
                        ),
                        onClick: (rowIndex: number) => handleDeleteClick(rowIndex),
                        disabled: (rowIndex: number) => !isDateTimeGreaterThan30MinutesAgo(savedVitals[rowIndex]?.date_time),
                    },
                ]}
            />
            <DeleteConfirmationModal
                open={isDeleteModalOpen}
                handleClose={handleDeleteModalClose}
                handleConfirm={handleDeleteConfirm}
            />
        </>
    );
};

export default DailyProgressNotes;
