
import React, { useCallback, useEffect, useState } from 'react';
import {
    Autocomplete,
    TextField,
    Button,
    Box,
    Typography,
    Chip,
    Grid,
    Divider,
    Checkbox,
    Dialog,
    AutocompleteChangeReason,
    AutocompleteChangeDetails,
    FormControlLabel,
    Tooltip,
} from '@mui/material';
import debounce from 'lodash.debounce';
import axios from 'axios';
import CustomAutocomplete from '../../../../autocomplete-field';
import { Bounce, toast } from "react-toastify";
import DynamicTable from '../../../../Dynamic-table';
import MedicineDoses, { MedicineDose } from '../../../../OPD/add-patient-record/MedicineDoses';
import AddNewTestProc from '../../../../OPD/add-patient-record/AddNewTestProc';
import { fetchSymptoms, selectSymptoms } from '../../../../../redux/reducers/OPD/Admin/symptomsSlice';
import { fetchSigns, selectSigns } from '../../../../../redux/reducers/OPD/Admin/signsSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import RulesRegulationModal from './Rules&Regulation';

interface PreSurgicalProps {
    onChange: (data: any) => void;
    data: any;
    otherTabsData: any;
    profile: any;
    opdData: any
}

const PreSurgical: React.FC<PreSurgicalProps> = ({ onChange, data, otherTabsData, profile, opdData }) => {
    const [formData, setFormData] = useState<any>({
        pre_Operation: {
            consent: '',
            npo: true,
            maintain_IV_Line: true,
            prepare_Area: true,
            take_Special_Consent: true,
        },
        medicines: [],
        symptoms: [],
        diagnosis: [],
        sings: [],
        anaesthetist: {},
        Surgeon: {},
        OT: {}
    });
    const [selectedSurgeon, setSelectedSurgeon] = useState<any>('')
    const [allDiagnosis, setAllDiagnosis] = useState<any[]>([]);
    const [selectedDiagnosis, setSelectedDiagnosis] = useState<any>([]);
    const [diagnosisName, setDiagnosisNames] = useState<any[]>([]);
    const [searchDiagnosis, setSearchDiagnosis] = useState<any>("");
    const [inputValueDiagnosis, setInputValueDiagnosis] = React.useState("");
    const [preMedicine, setPreMedicine] = useState<any>();
    const [medicineData, setMedicineData] = useState<MedicineDose[]>([]);
    const [selectedPrevTests, setSelectedPrevTests] = useState<any[]>([]);
    const [openTestModal, setOpenTestModal] = useState(false);
    const [prevSelectedDiagnosis, setPrevSelectedDiagnosis] = useState<any>([]);
    const [allSymptoms, setAllSymptoms] = useState<any[]>([]);
    // const [selectedSymptoms, setSelectedSymptoms] = useState<string[]>([]);
    const [selectedSymptoms, setSelectedSymptoms] = useState<(string | { symptomName: string })[]>([]);
    const [selectedPrevSymptoms, setSelectedPrevSymptoms] = useState<string[]>([]);
    const [inputValue, setInputValue] = React.useState("");
    const [allSigns, setAllSigns] = useState<any[]>([]);
    const [selectedSigns, setSelectedSigns] = useState<any[]>([]);
    const [signNames, setSignNames] = useState<any[]>([]);
    const [selectedPrevSigns, setSelectedPrevSigns] = useState<any[]>([]);
    const [inputValueSigns, setInputValueSigns] = React.useState("");
    const [AnaesthetistOptions, setAnaesthetistOptions] = React.useState<any[]>([]);
    const [SurgeonOptions, setSurgeonOptions] = React.useState<any[]>([]);
    const [OTOptions, setOTOptions] = React.useState<any[]>([]);
    const [routeAdm, setRouteAdm] = useState<any>();
    const signs = useSelector(selectSigns);
    const dispatch = useDispatch();

    const fetchFormData = async (formName: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${otherTabsData?._id}?formName=${formName}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            if (formName === "Investegation") {
                setSelectedPrevTests(response?.data?.data?.formData?.investigation || [])
            }
            else {
                setPreMedicine(response?.data?.data?.formData?.medicines)
            }
        } catch (error) {
            toast.error('Failed to fetch form data.');
        }
    };

    useEffect(() => {
        fetchFormData('Treatment');
        fetchFormData('Investegation');
    }, []);
    const getAnaesthetist = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/users/getAllUserByCategoryName?categoryName=Anaesthetist`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            });
            if (response?.data?.isSuccess === true) {
                setAnaesthetistOptions(response?.data?.data)
            }
        } catch (err) {
            console.error('Error fetching anesthetist:', err);
        }
    }
    const getSurgeon = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/users/getAllUserByCategoryName?department=Surgeon&categoryName=Doctor`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            });
            if (response?.data?.isSuccess === true) {
                setSurgeonOptions(response?.data?.data)
            }
        } catch (err) {
            console.error('Error fetching anesthetist:', err);
        }
    }
    const getOT = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/users/getAllUserByCategoryName?categoryName=OT Incharge`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            });
            if (response?.data?.isSuccess === true) {
                setOTOptions(response?.data?.data)
            }
        } catch (err) {
            console.error('Error fetching anesthetist:', err);
        }
    }
    useEffect(() => {
        getAnaesthetist()
        getSurgeon();
        getOT();
    }, [])

    useEffect(() => {
        onChange({ OT: formData?.OT, Surgeon: formData?.Surgeon, anaesthetist: formData?.anaesthetist, medicines: formData?.medicines, history: formData?.symptoms, diagnosis: formData?.diagnosis, examinations: formData?.signs, pre_Operation: formData?.pre_Operation, opd_diagnosis: prevSelectedDiagnosis, opd_history: selectedPrevSymptoms, opd_examination: selectedPrevSigns })
    }, [formData, prevSelectedDiagnosis, selectedPrevSymptoms, selectedPrevSigns])

    const handleAnaesthetistChange = async (value: any) => {
        if (value !== formData?.anaesthetist) {
            const newUpdatedFormData = {
                ...formData,
                anaesthetist: value || null
            };
            setFormData(newUpdatedFormData);
        }
    };

    const handleOTChange = async (value: any) => {
        if (value !== formData?.OT) {
            const newUpdatedFormData = {
                ...formData,
                OT: value || null // Set to null if no value is selected
            };

            setFormData(newUpdatedFormData);
        }
    };

    const handleSurgeonChange = async (value: any) => {
        // if (value !== formData?.Surgeon) {
        const newUpdatedFormData = {
            ...formData,
            Surgeon: value || null
        };
        setFormData(newUpdatedFormData);

        setSelectedSurgeon(value?.fullName)
    };

    useEffect(() => {
        const mergedDiagnosis = [
            ...(opdData?.diagnosis || []),
            ...(otherTabsData?.diagnosis || [])
        ];
        const uniqueDiagnosis = Array.from(new Set(mergedDiagnosis));

        setPrevSelectedDiagnosis(uniqueDiagnosis);
        setSelectedPrevSymptoms(opdData?.symptoms || []);
        setSelectedPrevSigns(opdData?.signs || []);
    }, [opdData, otherTabsData]);

    useEffect(() => {
        if (data) {
            setFormData((prev: any) => ({
                ...prev,
                diagnosis: data?.diagnosis || [],
                medicines: data?.medicines || [],
                symptoms: data?.history || [],
                signs: data?.examinations || [],
                pre_Operation: {
                    consent: data?.pre_Operation?.consent || '',
                    npo: data?.pre_Operation?.npo,
                    maintain_IV_Line: data?.pre_Operation?.maintain_IV_Line,
                    shave: data?.pre_Operation?.shave || '',
                    prepare_Area: data?.pre_Operation?.prepare_Area,
                    take_Special_Consent: data?.pre_Operation?.take_Special_Consent,
                },
                Surgeon: data?.Surgeon,
                OT: data?.OT,
                anaesthetist: data?.anaesthetist
            }));
            setDiagnosisNames(data?.details?.diagnosis || []);

            if (data?.diagnosis) {
                setSelectedDiagnosis(data?.diagnosis || [])
                setDiagnosisNames(data?.diagnosis || []);
            }
            if (data?.medicines) {
                setMedicineData(data?.medicines);
            } else {
                setMedicineData([]);
            }
            // setSelectedSymptoms(data?.symptoms);
            // if (Array.isArray(data?.symptoms)) {
            //     setSelectedSymptoms(data?.symptoms); // Keep full objects in state if needed
            // } else {
            //     setSelectedSymptoms([]);
            // }
            if (Array.isArray(data?.history)) {
                const symptomNames = data?.history;
                setSelectedSymptoms(symptomNames); // Set only the symptom names in the state
            } else {
                setSelectedSymptoms([]);
            }
            setSignNames(data?.examinations);
            // onChange({ ...data })
        }

        if (!data) {
            setFormData({
                pre_Operation: {
                    npo: true,
                    maintain_IV_Line: true,
                    prepare_Area: true,
                    take_Special_Consent: true,
                },
            })
        }
    }, [data]);

    const debouncedGetDiagnosis = useCallback(
        debounce(async (search: string) => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_OPD_API_URL}/diagnosis/getAllSelectAbleDiagnosis?search=${search}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                const fetchedDiagnosis = response?.data?.data || [];
                setAllDiagnosis(fetchedDiagnosis);
            } catch (error) {
                console.error(error);
            }
        }, 500),
        []
    );

    useEffect(() => {
        debouncedGetDiagnosis(searchDiagnosis);
    }, [debouncedGetDiagnosis, searchDiagnosis]);

    const sendAlertToStaff = async (staffType: string, staffId: string | undefined) => {
        const allDiagnoses = Array.from(
            new Map(
                [...selectedDiagnosis, ...prevSelectedDiagnosis].map((item: any) => [
                    item?._id || item,
                    item,
                ])
            ).values()
        );
        if (!staffId) {
            toast.error(`Please select a valid ${staffType}.`);
            return;
        }
        const payload = {
            staffId,
            staff_type: staffType,
            admissionId: otherTabsData?._id,
            diagnosis: allDiagnoses,
            procedure: opdData?.procedure,
            expectedTimeOfProcedure: "2 hr",
            otName: "10",
        };
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_IPD_API_URL}/operation-alert/sendAlertToStaff`,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            // Use the message from the backend response for the success toast
            toast.success(response.data.message || `${staffType} alert sent successfully!`);
        } catch (error: any) {
            console.error("Error sending alert:", error);

            // Use the message from the backend error response if available
            const errorMessage =
                error.response?.data?.message || `Failed to send ${staffType} alert.`;
            toast.error(errorMessage);
        }

    };

    const handlePreOperationChange = (name: string, value: any) => {
        const updatedFormData = {
            ...formData,
            pre_Operation: {
                ...formData.pre_Operation,
                [name]: value,
            },
        };
        setFormData(updatedFormData);
        // onChange(updatedFormData);
    };

    const handleSelectChangeDiagnosis = (event: { target: { value: any } }) => {
        const selectedDiagnosisIds = event.target.value;
        const selectedDiagnosisObjects = allDiagnosis?.filter((diagnosis: any) =>
            selectedDiagnosisIds?.includes(diagnosis?._id)
        );
        setSelectedDiagnosis(selectedDiagnosisObjects);

        const newDiagnosisNames = selectedDiagnosisObjects
            .filter((diagnosis: any) => !diagnosisName?.includes(diagnosis.diagnosisName))
            .map((diagnosis: any) => diagnosis?.diagnosisName);
        const finalDiagnosis = [...diagnosisName, ...newDiagnosisNames]
        setDiagnosisNames(finalDiagnosis);
    };

    const handleDeleteChipDiagnosis = (diagnosisToDelete: string) => {
        setSelectedDiagnosis((prevSelectedDiagnosis: any) =>
            prevSelectedDiagnosis?.filter((sign: any) => sign.diagnosisName !== diagnosisToDelete)
        );
        const newDiagnosisNames = diagnosisName.filter((diagnosis: any) => diagnosis !== diagnosisToDelete);
        setDiagnosisNames(newDiagnosisNames);
        const newData = {
            details: {
                ...formData.details,
                diagnosis: selectedDiagnosis?.map((diag: any) => diag._id),
            },
        };
        // onChange(newData);
    };

    const handleCreateNewDiagnosis = async (inputValue: string) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_OPD_API_URL}/diagnosis/createDiagnosis`,
                { diagnosisName: inputValue },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            const newDiagnosis = response?.data?.data;
            if (newDiagnosis) {
                setSelectedDiagnosis((prevSelected: any) => [...prevSelected, newDiagnosis]);
                setDiagnosisNames((prevNames) => [...prevNames, newDiagnosis?.diagnosisName]);
                setSearchDiagnosis("");

                setAllDiagnosis((prevGenerics: any) => [...prevGenerics, newDiagnosis]);
                toast.success(response.data?.message);
            }
        } catch (error: any) {
            console.error("Error creating new diagnosis:", error);
            toast.error(error.message);
        }
    };
    useEffect(() => {
        setFormData((prevData: any) => ({
            ...prevData,
            diagnosis: diagnosisName,
        }))
        // onChange({ ...formData, diagnosis: diagnosisName });

    }, [diagnosisName]);

    const handleInputChangeDiagnosis = (
        event: any,
        newInputValue: React.SetStateAction<string>
    ) => {
        setInputValueDiagnosis(newInputValue);
        setSearchDiagnosis(newInputValue);
    };

    const handleInputChangeDiagnosisBlur = () => {
        setInputValueDiagnosis("");
        setSearchDiagnosis("");
    };

    const handleAddButtonClickDiagnosis = () => {
        if (inputValueDiagnosis.trim() !== "") {
            handleCreateNewDiagnosis(inputValueDiagnosis);
            setInputValueDiagnosis("");
        }
    };

    const handleKeyPressDiagnosis = (event: { key: string; preventDefault: () => void; }) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleAddButtonClickDiagnosis();
        }
    };

    const handleMedicineDoses = (medicineDoses: any[]) => {
        const updatedFormData: any = {
            ...formData,
            medicines: medicineDoses,
        };
        setFormData(updatedFormData);
        // onChange(updatedFormData);
    };

    const symptoms = useSelector(selectSymptoms);

    useEffect(() => {
        dispatch(fetchSymptoms(inputValue) as any);
    }, [dispatch, inputValue]);

    useEffect(() => {
        setAllSymptoms(symptoms);
    }, [symptoms]);

    const handleSelectChangeSymptoms = (
        event: React.SyntheticEvent<Element, Event>,
        value: any[],
        reason?: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<any> | undefined
    ) => {
        // Filter out any undefined or invalid symptoms from the value array
        const selectedSymptomNames = value
            ?.map((symptom: any) => symptom?.symptomName)
            ?.filter((name: string | undefined) => name && name.trim() !== "");

        // Ensure prevSymptoms is always an array and handle the state update
        setSelectedSymptoms((prevSymptoms) => {
            const prevSymptomsArray = Array.isArray(prevSymptoms) ? prevSymptoms : [];
            const newSymptoms = [
                ...prevSymptomsArray,
                ...selectedSymptomNames?.filter(name => !prevSymptomsArray.includes(name))
            ];
            // Immediately update formData (this will not work until selectedSymptoms is updated)
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                symptoms: newSymptoms
            }));
            return newSymptoms;
        });
    };

    // useEffect(() => {
    //     onChange({
    //         ...formData,
    //         symptoms: formData?.symptoms
    //     });
    // }, [formData]);

    const handleDeleteChipSymptoms = (symptomToDelete: any) => {
        setSelectedSymptoms((prevSelectedSymptoms: any) =>
            prevSelectedSymptoms?.filter(
                (symptom: any) => symptom !== symptomToDelete
            )
        );
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            symptoms: prevFormData?.symptoms?.filter(
                (symptom: string) => symptom !== symptomToDelete
            ),
        }));
    };

    const handleCreateNewSymptoms = async (inputValue: string) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_OPD_API_URL}/symptoms/createSymptom?count='10000`,
                { symptomName: inputValue },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            const newSymptoms = response.data?.data;
            if (newSymptoms) {
                setSelectedSymptoms((prevSelected: any) => [
                    ...prevSelected,
                    newSymptoms?.symptomName,
                ]);
                setFormData((prevFormData: any) => ({
                    ...prevFormData,
                    symptoms: newSymptoms?.symptomName,
                }));
                // onChange({ ...formData, symptoms: newSymptoms?.symptomName })
                dispatch(fetchSymptoms(inputValue) as any);
                setAllSymptoms((prevGenerics) => {
                    return Array.isArray(prevGenerics)
                        ? [...prevGenerics, newSymptoms]
                        : [newSymptoms];
                });
                toast.success(response.data?.message);
            }
        } catch (error: any) {
            console.error("Error creating new symptom:", error);
            toast.error(error.message);
        }
    };

    const handleInputChange = (
        event: any,
        newInputValue: any
    ) => {
        setInputValue(newInputValue);
        dispatch(fetchSymptoms(newInputValue || "") as any); // Call with an empty string if null
    };

    const handleBlurSymptoms = () => {
        setInputValue("");
        dispatch(fetchSymptoms("") as any);
    };

    const handleAddButtonClick = () => {
        if (inputValue.trim() !== "") {
            handleCreateNewSymptoms(inputValue);
            setInputValue("");
        }
    };

    const handleKeyPress = (event: {
        key: string;
        preventDefault: () => void;
    }) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleAddButtonClick();
        }
    };

    useEffect(() => {
        setAllSigns(signs || []);
    }, [signs]);
    useEffect(() => {
        dispatch(fetchSigns(inputValueSigns) as any);
    }, [dispatch, inputValueSigns]);
    const handleCreateNewSigns = async (inputValue: string) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_OPD_API_URL}/signs/createSign`,
                { signName: inputValue },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            const newSigns = response.data?.data;
            if (newSigns) {
                // setAllSigns((prevGenerics: any) => [...prevGenerics, newSigns]);
                setSelectedSigns((prevSelected: any) => [...prevSelected, newSigns]);

                setSignNames((prevNames) => [
                    ...prevNames,
                    newSigns.signName,
                ]);
                await dispatch(fetchSigns(inputValueSigns) as any);
                setAllSigns((prevSymptoms) => [...prevSymptoms, newSigns]);
                toast.success(response.data?.message);
            }
        } catch (error: any) {
            console.error("Error creating new generic:", error);
            toast.error(error.message);
        }
    };
    const handleDeleteChip = (signToDelete: any) => {
        setSelectedSigns((prevSelectedSigns: any) =>
            prevSelectedSigns.filter((sign: any) => sign.signName !== signToDelete)
        );
        const newSignNames = signNames?.filter((sign: any) => sign !== signToDelete);
        setSignNames(newSignNames);

    };
    useEffect(() => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            signs: signNames,
        }));
    }, [signNames])
    // useEffect(() => {
    //     onChange({ ...formData, signs: formData?.signs });
    // }, [formData])

    // const handleSelectChangeSign = (event: { target: { value: any } }) => {
    //     const selectedSignIds = event.target.value;
    //     const selectedSignObjects = allSigns?.filter((sign: any) =>
    //         selectedSignIds?.includes(sign?._id)
    //     );
    //     setSelectedSigns(selectedSignObjects);

    //     const newSignNames = selectedSignObjects
    //         .filter((sign: any) => !signNames?.includes(sign?.signName))
    //         .map((sign: any) => sign?.signName);

    //     setSignNames([...signNames, ...newSignNames]);
    // };
    const handleSelectChangeSign = (event: { target: { value: any } }) => {
        const selectedSignIds = event.target.value;
        const selectedSignObjects = allSigns?.filter((sign: any) =>
            selectedSignIds?.includes(sign?._id)
        );
        setSelectedSigns(selectedSignObjects);

        const newSignNames = selectedSignObjects
            .filter((sign: any) => !signNames?.includes(sign?.signName))
            ?.map((sign: any) => sign?.signName);

        // Ensure signNames is always an array
        setSignNames([
            ...(Array.isArray(signNames) ? signNames : []),
            ...newSignNames,
        ]);
    };

    const handleInputChangeSigns = (
        event: any,
        newInputValue: any
    ) => {
        setInputValueSigns(newInputValue);
        dispatch(fetchSigns(newInputValue || "") as any);
    };

    const handleInputChangeSignsBlur = () => {
        setInputValueSigns("");
        dispatch(fetchSigns("") as any);
    };

    const handleAddButtonClickSigns = () => {
        if (inputValueSigns.trim() !== "") {
            handleCreateNewSigns(inputValueSigns);
            setInputValueSigns("");
        }
    };

    const handleKeyPressSigns = (event: {
        key: string;
        preventDefault: () => void;
    }) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleAddButtonClickSigns();
        }
    };

    const getRouteAdm = async () => {
        const response = await axios.get(
            `${process.env.REACT_APP_OPD_API_URL}/routes-of-administration/getAllByPagination`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                params: {
                    count: 50000,
                    pageNo: 1,
                },
            }
        );
        setRouteAdm(response?.data?.data);
    };
    useEffect(() => {
        getRouteAdm();
    }, []);
    return (
        <Box >
            <Grid container spacing={1}>
                <Grid item md={2.4} sm={3} xs={4}>
                    <Box >
                        <Typography
                            sx={{
                                fontWeight: "600",
                                fontSize: "12px",
                                color: "#000",
                            }}
                        >
                            Prov. Diagnosis/DD:{" "}
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Typography sx={{ fontSize: '10px', color: '#000', fontWeight: '500' }}>
                                {otherTabsData?.finalDiagnosis && <Tooltip title='Final Diagnosis' placement='top'>
                                    <span style={{ background: '#FFCCCB', fontSize: '10px', padding: '2px', color: '#000' }}>{otherTabsData?.finalDiagnosis}</span></Tooltip>} {prevSelectedDiagnosis?.slice(0, 2).join(', ')}
                                {prevSelectedDiagnosis?.length > 2 && (
                                    <Tooltip title={prevSelectedDiagnosis?.slice(2)?.join(', ')} arrow placement='top'>
                                        <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                                            +{prevSelectedDiagnosis?.length - 2} more
                                        </span>
                                    </Tooltip>
                                )}
                            </Typography>
                        </Box>

                    </Box>
                </Grid>
                <Grid item md={2.4} sm={3} xs={4}>
                    <Typography sx={{
                        fontWeight: "600",
                        fontSize: "12px",
                        color: "#000",
                    }}>Prev. Tests</Typography>
                    <Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Typography sx={{ fontSize: '10px', color: '#000', fontWeight: '500' }}>
                                {
                                    Array.isArray(selectedPrevTests) && selectedPrevTests?.slice(0, 2)?.map((test: any) => test?.investigation_Name).join(', ') || 'No Previous Tests'
                                }
                                {selectedPrevTests?.length > 2 && (
                                    <Tooltip placement='top'
                                        title={Array.isArray(selectedPrevTests) && selectedPrevTests?.slice(2)?.map((test: any) => test?.investigation_Name)?.join(', ')}
                                        arrow
                                    >
                                        <span style={{ cursor: 'pointer', color: '#1976d2' }}> +{selectedPrevTests?.length - 2} more</span>
                                    </Tooltip>
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2.4} sm={3} xs={4}>
                    {/* Surgeon */}
                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>Surgeon:</Typography>
                    <CustomAutocomplete
                        options={SurgeonOptions} // Array of Surgeon objects
                        value={formData?.Surgeon} // Ensure the value is valid or null
                        onChange={(event, newValue) => handleSurgeonChange(newValue)} // Handle selection for Surgeon
                        getOptionLabel={(option) => option?.fullName || ""} // Only display the fullName
                        filterOptions={(options, { inputValue }) =>
                            options.filter((option: any) =>
                                option?.fullName?.toLowerCase().includes(inputValue.toLowerCase())
                            )
                        }
                        placeholder="Select Surgeon"
                    />
                    <Button
                        variant="contained"
                        size="small"
                        sx={{
                            mt: 1,
                            fontSize: "10px",
                            width: "120px",
                            height: "23px",
                            padding: "4px 8px",
                            backgroundColor: "#25D366",
                            color: "#fff",
                            gap: "5px",
                            "&:hover": { backgroundColor: "#1DA859" },
                            borderRadius: "4px",
                        }}
                        onClick={() => sendAlertToStaff("Surgeon", formData?.Surgeon?._id)} // Pass the Surgeon ID
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // WhatsApp logo URL
                            alt="WhatsApp Icon"
                            style={{ width: '12px', height: '12px' }} // Adjust size of the icon
                        />
                        Send Request
                    </Button>
                </Grid>

                <Grid item md={2.4} sm={3} xs={4}>
                    {/* Anaesthetist */}
                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>Anaesthetist:</Typography>
                    <CustomAutocomplete
                        options={AnaesthetistOptions} // Array of Anaesthetist objects
                        value={formData?.anaesthetist || null} // Ensure the value is valid or null
                        onChange={(event, newValue) => handleAnaesthetistChange(newValue)} // Handle selection for Anaesthetist
                        getOptionLabel={(option) => option?.fullName || ""} // Only display the fullName in dropdown
                        filterOptions={(options, { inputValue }) =>
                            options.filter((option: any) =>
                                option?.fullName?.toLowerCase().includes(inputValue.toLowerCase())
                            )
                        }
                        placeholder="Select Anaesthetist"
                    />
                    <Button
                        variant="contained"
                        sx={{
                            mt: 1,
                            gap: "5px",
                            fontSize: "10px",
                            width: "120px",
                            height: "23px",
                            padding: "4px 8px",
                            backgroundColor: "#25D366",
                            color: "#fff",
                            "&:hover": { backgroundColor: "#1DA859" },
                            borderRadius: "4px",
                        }}
                        onClick={() => sendAlertToStaff("Anaesthetist", formData?.anaesthetist?._id)} // Pass only the ID to the function
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // WhatsApp logo URL
                            alt="WhatsApp Icon"
                            style={{ width: '12px', height: '12px' }} // Adjust size of the icon
                        />
                        Send Request
                    </Button>
                </Grid>

                <Grid item md={2.4} sm={3} xs={4}>
                    {/* OT */}
                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>OT:</Typography>
                    <CustomAutocomplete
                        options={OTOptions} // Array of OT objects
                        value={formData?.OT || null} // Ensure the value is valid or null
                        onChange={(event, newValue) => handleOTChange(newValue)} // Handle selection for OT
                        getOptionLabel={(option) => option?.fullName || ""} // Only display the fullName
                        filterOptions={(options, { inputValue }) =>
                            options.filter((option: any) =>
                                option?.fullName?.toLowerCase().includes(inputValue.toLowerCase())
                            )
                        }
                        placeholder="Select OT"
                    />
                    <Button
                        variant="contained"
                        sx={{
                            mt: 1,
                            fontSize: "10px",
                            width: "120px", // Adjust width to accommodate icon
                            height: "23px", // Adjust height for better appearance
                            padding: "4px 8px",
                            backgroundColor: "#25D366",
                            color: "#fff",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "5px", // Spacing between icon and text
                            "&:hover": { backgroundColor: "#1DA859" },
                            borderRadius: "4px",
                        }}
                        onClick={() => sendAlertToStaff("OT Incharge", formData?.OT?._id)}
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // WhatsApp logo URL
                            alt="WhatsApp Icon"
                            style={{ width: '12px', height: '12px' }} // Adjust size of the icon
                        />
                        Send Request
                    </Button>
                </Grid>
            </Grid>

            <Typography sx={{ fontSize: '14px', fontWeight: '800', mt: 1 }}>Pre-Operation Checklist</Typography>
            <Grid container spacing={1}>
                <Grid item md={2.4} sm={3} xs={4} >
                    <Typography
                        sx={{
                            fontWeight: "600",
                            fontSize: "12px",
                            color: "#000",
                        }}
                    >
                        Consent :{" "}
                    </Typography>
                    <TextField
                        placeholder="Consent"
                        name='consent'
                        value={formData?.pre_Operation?.consent}
                        onChange={(e) => handlePreOperationChange('consent', e.target.value)}
                        fullWidth
                        className='search-field'
                        size='small'
                        margin="normal"
                        sx={{ marginTop: '0px' }}
                    />
                </Grid>
                <Grid item md={2.4} sm={3} xs={4} >
                    <Typography
                        sx={{
                            fontWeight: "600",
                            fontSize: "12px",
                            color: "#000",
                        }}
                    >
                        History :{" "}
                    </Typography>
                    <CustomAutocomplete
                        multiple
                        options={allSymptoms}
                        getOptionLabel={(option) => option?.symptomName}
                        filterOptions={(options, { inputValue }) =>
                            options?.filter((option: any) =>
                                option?.symptomName?.toLowerCase()?.includes(inputValue?.toLowerCase())
                            )
                        }
                        onChange={(event, newValue) =>
                            handleSelectChangeSymptoms(event, newValue)
                        }
                        value={selectedSymptoms}
                        placeholder="Select History"
                        onTextFieldChange={(event) =>
                            handleInputChange(event, event.target.value)
                        }
                        handleBlur={handleBlurSymptoms}
                        onKeyPress={handleKeyPress}
                        handleAddClick={handleAddButtonClick}
                    />

                    <Box mt={0.4} sx={{}}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '5px' }}>
                            {/* <Typography sx={{ fontSize: '10px', color: '#000', fontWeight: '500' }}>
                                {Array.isArray(selectedPrevSymptoms) && selectedPrevSymptoms?.flat()?.join(', ') || 'No Previous History'}
                            </Typography> */}
                            <Typography sx={{ fontSize: '10px', color: '#000', fontWeight: '500' }}>
                                {Array.isArray(selectedPrevSymptoms) && selectedPrevSymptoms?.slice(0, 2).join(', ')}
                                {selectedPrevSymptoms?.length > 2 && (
                                    <Tooltip title={Array.isArray(selectedPrevSymptoms) && selectedPrevSymptoms?.slice(2)?.join(', ')} arrow placement='top'>
                                        <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                                            +{selectedPrevSymptoms?.length - 2} more
                                        </span>
                                    </Tooltip>
                                )}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginTop: "8px",
                                background:
                                    selectedSymptoms?.length > 0
                                        ? "rgba(192, 192, 192, 0.22)"
                                        : "",
                                border:
                                    selectedSymptoms?.length > 0
                                        ? "2px solid #D8D8D8"
                                        : "",
                                p: 0.5,
                                borderRadius: "12px",
                                gap: 0.5,
                            }}
                        >
                            {selectedSymptoms?.map((symptom, index) => (
                                <Chip
                                    key={index}
                                    label={typeof symptom === 'string' ? symptom : symptom.symptomName}  // Check if the element is a string or object
                                    onDelete={() => handleDeleteChipSymptoms(symptom)}  // Use the object or string directly for deletion
                                    sx={{
                                        borderRadius: "8px",
                                        background: "#fff",
                                        fontSize: "12px",
                                        height: "24px",
                                        "&:hover": {
                                            background: "#055FFC",
                                            color: "#fff",
                                            "& .MuiChip-deleteIcon": {
                                                color: "#fff",
                                            },
                                            "& .MuiChip-icon": {
                                                color: "#fff",
                                            },
                                        },
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2.4} sm={3} xs={4} >
                    <Typography
                        sx={{
                            fontWeight: "600",
                            fontSize: "12px",
                            color: "#000",
                        }}
                    >
                        Examination :{" "}
                    </Typography>
                    <CustomAutocomplete
                        multiple
                        options={allSigns || []}
                        value={selectedSigns || []}
                        getOptionLabel={(option) => option?.signName}
                        filterOptions={(options, { inputValue }) =>
                            options?.filter((option: any) =>
                                option?.signName
                                    ?.toLowerCase()
                                    ?.includes(inputValue.toLowerCase())
                            )
                        }
                        onChange={(event, newValue) => {
                            handleSelectChangeSign({
                                target: {
                                    value: newValue?.map((sign: any) => sign._id),
                                },
                            });
                        }}
                        placeholder="Examination"
                        onTextFieldChange={(event) =>
                            handleInputChangeSigns(event, event.target.value)
                        }
                        handleBlur={handleInputChangeSignsBlur}
                        onKeyPress={handleKeyPressSigns}
                        handleAddClick={handleAddButtonClickSigns}
                    />
                    <Box mt={0.4}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '5px' }}>
                            {/* <Typography sx={{ fontSize: '10px', color: '#000', fontWeight: '500' }}>
                                {selectedPrevSigns?.flat()?.join(', ') || 'No Previous Examinations'}
                            </Typography> */}
                            <Typography sx={{ fontSize: '10px', color: '#000', fontWeight: '500' }}>
                                {Array.isArray(selectedPrevSigns) && selectedPrevSigns?.slice(0, 2).join(', ')}
                                {selectedPrevSymptoms?.length > 2 && (
                                    <Tooltip title={Array.isArray(selectedPrevSigns) && selectedPrevSigns?.slice(2)?.join(', ')} arrow placement='top'>
                                        <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                                            +{selectedPrevSigns?.length - 2} more
                                        </span>
                                    </Tooltip>
                                )}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginTop: "2px",
                                background:
                                    signNames?.length > 0 ? "rgba(192, 192, 192, 0.2)" : "",
                                border: signNames?.length > 0 ? "2px solid #D8D8D8" : "",
                                p: 0.5,
                                borderRadius: "12px",
                            }}
                        >
                            {signNames?.map((sign: any, index) => (
                                <Chip
                                    size="small"
                                    key={index}
                                    // label={sign}
                                    label={typeof sign === 'string' ? sign : sign.signName}
                                    onDelete={() => handleDeleteChip(sign)}
                                    sx={{
                                        // margin: "2px",
                                        borderRadius: "8px",
                                        background: "#fff",
                                        border: "1px solid rgba(0, 0, 0, 0.22)",
                                        fontSize: "12px",
                                        "&:hover": {
                                            background: "#055FFC",
                                            color: "#fff",
                                            "& .MuiChip-deleteIcon": {
                                                color: "#fff", // Change delete icon color on hover
                                            },
                                            "& .MuiChip-icon": {
                                                color: "#fff", // Change icon color on hover if you have an icon
                                            },
                                        },
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2.4} sm={3} xs={4} >
                    <Typography
                        sx={{
                            fontWeight: "600",
                            fontSize: "12px",
                            color: "#000",
                        }}
                    >
                        Prov. Diagnosis :{" "}
                    </Typography>
                    <CustomAutocomplete
                        multiple
                        options={allDiagnosis || []}
                        value={selectedDiagnosis || []}
                        onChange={(event, newValue) => {
                            handleSelectChangeDiagnosis({
                                target: {
                                    value: newValue?.map((diag: any) => diag._id),
                                },
                            });
                        }}
                        getOptionLabel={(option) => option?.diagnosisName}
                        filterOptions={(options, { inputValue }) =>
                            options?.filter((option: any) =>
                                option?.diagnosisName?.toLowerCase().includes(inputValue.toLowerCase())
                            )
                        }
                        placeholder="Provisional Diagnosis/DD"
                        onTextFieldChange={(event) =>
                            handleInputChangeDiagnosis(event, event.target.value)
                        }
                        handleBlur={handleInputChangeDiagnosisBlur}
                        onKeyPress={handleKeyPressDiagnosis}
                        handleAddClick={handleAddButtonClickDiagnosis}
                    />
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Typography sx={{ fontSize: '10px', color: '#000', fontWeight: '500' }}>
                           {otherTabsData?.finalDiagnosis && <Tooltip title='Final Diagnosis' placement='top'>
                                <span style={{ background: '#FFCCCB', fontSize: '10px', padding: '2px', color: '#000' }}>{otherTabsData?.finalDiagnosis}</span></Tooltip>} {prevSelectedDiagnosis?.slice(0, 2).join(', ')}
                            {prevSelectedDiagnosis?.length > 2 && (
                                <Tooltip title={prevSelectedDiagnosis?.slice(2)?.join(', ')} arrow placement='top'>
                                    <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                                        +{prevSelectedDiagnosis?.length - 2} more
                                    </span>
                                </Tooltip>
                            )}
                        </Typography>
                    </Box>

                    <Box mt={0.4}>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                background: diagnosisName?.length > 0 ? "rgba(192, 192, 192, 0.2)" : "",
                                border: diagnosisName?.length > 0 ? "2px solid #D8D8D8" : "",
                                p: 0.5,
                                borderRadius: "12px",
                            }}
                        >
                            {diagnosisName?.map((sign: any, index: any) => (
                                <Chip
                                    size="small"
                                    key={index}
                                    label={sign}
                                    onDelete={() => handleDeleteChipDiagnosis(sign)}
                                    sx={{
                                        borderRadius: "8px",
                                        background: "#fff",
                                        border: "1px solid rgba(0, 0, 0, 0.22)",
                                        fontSize: "12px",
                                        "&:hover": {
                                            background: "#055FFC",
                                            color: "#fff",
                                            "& .MuiChip-deleteIcon": {
                                                color: "#fff",
                                            },
                                            "& .MuiChip-icon": {
                                                color: "#fff",
                                            },
                                        },
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2.4} sm={12} xs={8}>
                    <Box display='flex' alignItems='center' flexWrap='wrap'>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData?.pre_Operation?.npo} // Boolean value (true or false)
                                    onChange={(e) => handlePreOperationChange('npo', e.target.checked)} // Passes true/false
                                    color="primary"
                                    size='small'
                                />
                            }
                            label="NPO :"
                            sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px', fontWeight: '500' } }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData?.pre_Operation?.maintain_IV_Line} // Boolean value (true or false)
                                    onChange={(e) => handlePreOperationChange('maintain_IV_Line', e.target.checked)} // Passes true/false
                                    color="primary"
                                    size='small'
                                />
                            }
                            label="Maintain IV Line :"
                            sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px', fontWeight: '500' } }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData?.pre_Operation?.prepare_Area} // Boolean value (true or false)
                                    onChange={(e) => handlePreOperationChange('prepare_Area', e.target.checked)} // Passes true/false
                                    color="primary"
                                    size='small'
                                />
                            }
                            label="Shave & Prepare the Area Mark the Operation Site :"
                            sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px', fontWeight: '500' } }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData?.pre_Operation?.take_Special_Consent} // Boolean value (true or false)
                                    onChange={(e) => handlePreOperationChange('take_Special_Consent', e.target.checked)} // Passes true/false
                                    color="primary"
                                    size='small'
                                />
                            }
                            label="Take Special Consent :"
                            sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px', fontWeight: '500' } }}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Grid container >
                <Typography sx={{ fontSize: '12px', fontWeight: '600' }}> Indoor medicine (Already taking):</Typography>
                <Grid item md={12} sm={12} xs={12}>
                    <Box sx={{
                        maxHeight: '100px',
                        overflowY: 'auto',
                        "&::-webkit-scrollbar": {
                            width: "5px"
                        },
                        "&::-webkit-scrollbar-track": {
                            background: "transparent"
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "#888",
                            borderRadius: "5px"
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                            background: "#555"
                        },
                    }}>
                        <DynamicTable
                            headers={[
                                "SN",
                                "Medicine",
                                "Strength",
                                "Route",
                                "Frequency",
                                "Duration",
                                "Doctor Name",
                                "Date & Time"
                            ]}
                            data={preMedicine?.map((item: any, index: any) => [
                                index + 1,
                                item?.name,
                                item?.strength,

                                (() => {
                                    const route = routeAdm?.find(
                                        (route: any) =>
                                            route?._id === item?.routAdministrationId
                                    );
                                    return route ? route?.RA_Abbreviation : "-";
                                })(),
                                item?.frequency?.abr_notation,
                                item?.duration,
                                item?.doctorName || "-",
                                item?.dateTime || "-"

                            ])}
                        />

                    </Box>
                </Grid>

            </Grid>

            <Grid container sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: '12px', fontWeight: '600', mb: 1 }}> Pre-OP medicine (Ordered by Surgeon) :</Typography>
                <Grid item md={12}>
                    <Box >
                        <MedicineDoses
                            onMedicineDoses={handleMedicineDoses}
                            medicineDataIfExist={medicineData}
                            setMedicineData={setMedicineData}
                            medicineData={medicineData}
                            treatment={true}
                        />
                    </Box>
                </Grid>
            </Grid>


        </Box >
    );
};

export default PreSurgical;
